import React, { Component } from 'react';
import Carousel from './../../carousel/Carousel';
import NavBar from "./../../nav-bar/NavBar";
import Footer from "./../../footer/Footer";
import { CardGrid } from './components/Cards';
import { GroupTypeData } from '../../../data/GroupTypeData';
import { CarouselData } from '../../../data/CarouselData';
import { LeaderData } from '../../../data/LeaderData';
import LeaderGrid from './components/LeaderGrid';
import './Homepage.css';

class Homepage extends Component {

    render() {
        return(
            <div>
                <NavBar location='/'/>
                <main>
                    <Carousel 
                        sources={CarouselData}
                        speed="7000"
                    />
                    <div className="text-wrapper">
                        <h3 className="category-header">Why Small Groups?</h3>
                        <p>
                            We've all been starving for human contact and connection in
                            recent months. We've been told to "socially distance" ourselves from
                            one another in the name of public safety. While we believe the safety
                            of our community is important, we also believe human contact
                            and connection is essential for our health and well-being. 
                        </p>
                    </div>
                    {/* 16:9 aspect ratio */}
                    <div className="embed-responsive embed-responsive-16by9">
                    <iframe 
                        width="560" 
                        height="315" 
                        src="https://www.youtube.com/embed/lWd34mqUPFo" 
                        frameBorder="0" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                        allowFullScreen="allowfullscreen"
                        title="main promo"
                    />
                    </div>
                    <div className="text-wrapper" id="join-by-category">
                        <h3 className="category-header">Join a Group by Category</h3>
                        <p>
                            Love sending it with a bunch of bros cheering you on? Maybe flinging paint
                            on a canvas in the shape of a starry night? Or maybe you love good ole
                            fashioned discussion with fellow intellectuals. We have the family for you.
                        </p>
                    </div>
                    <CardGrid cards={GroupTypeData}/>
                    <div className="text-wrapper" id="join-by-category">
                        <h3 className="category-header">Meet the Group Leaders</h3>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor 
                            incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud 
                            exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure 
                            dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. 
                            Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt 
                            mollit anim id est laborum.
                        </p>
                    </div>
                    <LeaderGrid leaders={LeaderData}/>
                </main>
                <Footer />
            </div>
        )
    }
}

export default Homepage
