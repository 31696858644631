import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { fireFuncs } from './../../../Fire';
import NavBar from './../../nav-bar/NavBar';
import './Playground.css';

const Playground = props => {
    const [message, setMessage] = useState('Nothing yet')
    
    const testAdd = event => {
        console.log("Testing add function")
        var add = fireFuncs.httpsCallable('add');
        add({ num1: 2, num2: 5 })
        .then((result) => {
            // Read result of the Cloud Function.
            setMessage(result.data.result);
        }).catch(error => {
            console.error(error);
            setMessage("Error: " + error.details)
        })
    }

    return (
        <>
            <NavBar location='/playground'/>
            <div className="playground-page">
            <h1>This is the playground</h1>
                <p>Used for testing cloud functions or whatever.</p>
                <div className="playground-test-container">
                    <h3 className="playground-test-header">Add Function</h3>
                    <p className="playground-btn-description">
                        This sends 2 and 5 to the function and should return 7
                    </p>
                    <div className="playground-btn-wrapper">
                        <Button variant="primary" onClick={testAdd}>Test addMessage</Button>
                    </div>
                    <p className="playground-test-result-header">Response:</p>
                    <p className="playground-test-result">{message}</p>
                </div>
            </div>
        </>
    )
}

export default Playground;