import * as EmailValidator from 'email-validator';
import { AsYouType } from 'libphonenumber-js';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import phone from 'phone';
import React, {Component} from 'react';
import './CreateAccount.css';
import { fireAuth, rtDatabase } from '../../../Fire';
import { Redirect } from 'react-router-dom';

class CreateAccount extends Component {
    constructor(props) {
        super(props);

        this.state = {
            verified: false,
            firstNameValue: '',
            firstNameValid: '',
            lastNameValue: '',
            lastNameValid: '',
            phoneValue: '',
            phoneValid: '',
            roleValue: 'Select role at WWU',
            roleValid: '',
            emailValue: '',
            emailValid: '',
            passwordValue: '',
            passwordValid: '',
            passwordCheckValue: '',
            passwordCheckValid: '',
            doneRedirect: false,
            pageError: ''
        }
    }

    capitalizeStr(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    isValidName(str) {
        var regex = /^[a-zA-Z-]*$/;
        return regex.test(str);
    }

    isValidEmail(email) {
        return EmailValidator.validate(email);
    }

    isValidPhone(phoneNumber) {
        // parsedPhone is an array. Index 0 has parsed number, and index 1 has country code
        var parsedPhone = phone(phoneNumber);
        return this.state.phoneValue !== '' && isPossiblePhoneNumber(parsedPhone[0]);
    }

    handleFirstNameChange = event => {
        let newValue = this.capitalizeStr(event.target.value);
        this.setState({firstNameValue: newValue}, () => {
            if (this.isValidName(this.state.firstNameValue)) {
                this.setState({firstNameValid: true}, this.verifyForm);
            } else {
                this.setState({firstNameValid: false}, this.verifyForm);
            }
        });
    }

    handleLastNameChange = event => {
        let newValue = this.capitalizeStr(event.target.value);
        this.setState({lastNameValue: newValue}, () => {
            if (this.isValidName(this.state.lastNameValue)) {
                this.setState({lastNameValid: true}, this.verifyForm);
            } else {
                this.setState({lastNameValid: false}, this.verifyForm);
            }
        });    
    }

    handlePhoneChange = event => {
        var formatter = new AsYouType('US');
        let newValue = event.target.value;
        newValue = formatter.input(newValue);
        this.setState({phoneValue: newValue}, () => {
            if (this.isValidPhone(this.state.phoneValue)) {
                this.setState({phoneValid: true}, this.verifyForm);
            } else {
                this.setState({phoneValid: false}, this.verifyForm);
            }
        });
    }

    handleRoleChange = event => {
        let newValue = event.target.value;
        this.setState({roleValue: newValue}, () => {
            if (this.state.roleValue === 'Select role at WWU') {
                this.setState({roleValid: false}, this.verifyForm);
            } else {
                this.setState({roleValid: true}, this.verifyForm);
            }
        });
    }

    handleEmailChange = event => {
        let newValue = event.target.value;
        this.setState({emailValue: newValue}, () => {
            if (this.isValidEmail(this.state.emailValue)) {
                this.setState({emailValid: true}, this.verifyForm);
            } else {
                this.setState({emailValid: false}, this.verifyForm);
            }
        });
    }
    
    handlePasswordChange = event => {
        let newValue = event.target.value;
        this.setState({passwordValue: newValue}, () => {
            if (this.state.passwordValue.length < 12 || this.state.passwordValue.length > 32) {
                this.setState({passwordValid: false}, this.verifyForm);
            } else {
                this.setState({passwordValid: true}, this.verifyForm);
            }
        });
    }
    
    handlePasswordCheckChange = event => {
        let newValue = event.target.value;
        this.setState({passwordCheckValue: newValue}, () => {
            if (this.state.passwordCheckValue === this.state.passwordValue) {
                this.setState({passwordCheckValid: true}, this.verifyForm);
            } else {
                this.setState({passwordCheckValid: false}, this.verifyForm);
            }    
        });
    }

    verifyForm = () => {
        if (this.state.firstNameValid &&
            this.state.lastNameValid &&
            this.state.phoneValid &&
            this.state.roleValid &&
            this.state.emailValid &&
            this.state.passwordValid &&
            this.state.passwordCheckValid) 
        {
            this.setState({verified: true});
        } else {
            this.setState({verified: false});
        }
    }

    getDate = () => {
        var d = new Date();
        var date = {
            month: d.getMonth() + 1,
            day: d.getDate(),
            year: d.getFullYear()
        }
        return date
    }

    handleSubmit = event => {
        fireAuth.createUserWithEmailAndPassword(this.state.emailValue, this.state.passwordValue)
        .then(() => {
          // Signed in 
          // ...
          console.log("user is signed up");
          var currentUser = fireAuth.currentUser;
          currentUser.updateProfile({
              displayName: this.state.firstNameValue + " " + this.state.lastNameValue,
          }).then(() => {
              console.log("user info was added successfully");
              console.log("user name: " + currentUser.displayName);
              console.log("user uid: " + currentUser.uid);
              console.log("user email: " + currentUser.email);

              // add user data to database
              rtDatabase.ref('users/' + currentUser.uid).set({
                firstname: this.state.firstNameValue,
                lastname: this.state.lastNameValue,
                email: this.state.emailValue,
                phone: this.state.phoneValue,
                permissions: "member",
                uid: currentUser.uid,
                dateAdded: this.getDate(),
                wwuRole: this.state.roleValue,
                groups: {
                    placeholder: ''
                }
              }).then(() => {
                  this.setState({doneRedirect: true});
              }).catch(error => {
                  // error with adding user info to database
                  // delete account that was just created
                  currentUser.delete().then(() => {
                      this.setState({pageError: error})
                  }).catch(error2 => {
                      this.setState({pageError: error2})
                  })
              })
              
          }).catch(error => {
            this.setState({pageError: error})
          })
        })
        .catch((error) => {
          this.setState({pageError: error})
        });
    }
    
    render() {
        if (this.state.pageError !== '') {
            return <Redirect to={{
                pathname: '/error',
                state: { code: this.state.pageError.code, message: this.state.pageError.message }
            }} />;
        }
        if (this.state.doneRedirect) {
            return <Redirect to='/' />;
        }
        
        let firstNameValidClass = '';
        if (this.state.firstNameValid !== '') {
            firstNameValidClass = (this.state.firstNameValid ? '' : 'is-invalid');
        }
        let lastNameValidClass = '';
        if (this.state.lastNameValid !== '') {
            lastNameValidClass = (this.state.lastNameValid ? '' : 'is-invalid');
        }
        let phoneValidClass = '';
        if (this.state.phoneValid !== '') {
            phoneValidClass = (this.state.phoneValid ? '' : 'is-invalid');
        }
        let roleValidClass = '';
        if (this.state.roleValid !== '') {
            roleValidClass = (this.state.roleValid ? '' : 'is-invalid');
        }
        let emailValidClass = '';
        if (this.state.emailValid !== '') {
            emailValidClass = (this.state.emailValid ? '' : 'is-invalid');
        }
        let passwordValidClass = '';
        if (this.state.passwordValid !== '') {
            passwordValidClass = (this.state.passwordValid ? '' : 'is-invalid');
        }
        let passwordCheckValidClass = '';
        if (this.state.passwordCheckValid !== '') {
            passwordCheckValidClass = (this.state.passwordCheckValid ? '' : 'is-invalid');
        }

        return (
            <div className="create-account-page" style={{backgroundImage: 'url(/img/music/journey3.jpg)'}}>
                <div className="create-account-card">
                    <div id="signUpForm" className="create-account-card-wrapper form" noValidate>
                        <h2 className="create-account-header">Create Account</h2>
                        <p>Tell us about yourself</p>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="addon-wrapping">
                                    <img className="create-account-icons" src="/img/icons/person.svg" alt="person icon" title="envelope" />
                                </span>
                            </div>
                            <input onChange={this.handleFirstNameChange} value={this.state.firstNameValue} type="name" className={"form-control " + firstNameValidClass} placeholder="first name" aria-label="name" aria-describedby="addon-wrapping" />
                            <input onChange={this.handleLastNameChange} value={this.state.lastNameValue} type="name" className={"form-control " + lastNameValidClass} placeholder="last name" aria-label="name" aria-describedby="addon-wrapping" />
                            <div className="invalid-feedback">Enter a valid name</div>
                        </div>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="addon-wrapping">
                                    <img className="create-account-icons" src="/img/icons/telephone.svg" alt="phone icon" title="phone" />
                                </span>
                            </div>
                            <input onChange={this.handlePhoneChange} value={this.state.phoneValue} type="tel" id="telInput" className={"form-control " + phoneValidClass} placeholder="phone number" aria-label="phone number" aria-describedby="addon-wrapping" required/>
                            <div className="invalid-feedback">Please provide a valid phone number</div>
                        </div>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="addon-wrapping">
                                    <img className="create-account-icons" src="/img/icons/person-badge.svg" alt="person badge icon" title="badge" />
                                </span>
                            </div>
                            <select onChange={this.handleRoleChange} className={"form-control " + roleValidClass}>
                                <option>Select role at WWU</option>
                                <option>Freshman</option>
                                <option>Sophomore</option>
                                <option>Junior</option>
                                <option>Senior</option>
                                <option>Grad Student</option>
                                <option>Faculty/Staff</option>
                                <option>Community Member</option>
                            </select>
                            <div className="invalid-feedback">Must select a role</div>
                        </div>
                        <p className="create-account-lower-p">Login Credentials</p>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="addon-wrapping">
                                    <img className="create-account-icons" src="/img/icons/envelope.svg" alt="envelop icon" title="envelope" />
                                </span>
                            </div>
                            <input onChange={this.handleEmailChange} type="email" id="emailInput" className={"form-control " + emailValidClass} placeholder="email" aria-label="email" aria-describedby="addon-wrapping" required/>
                            <div className="invalid-feedback">Please provide a valid email</div>
                        </div>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="addon-wrapping">
                                    <img className="create-account-icons" src="/img/icons/key.svg" alt="key icon" title="key" />
                                </span>
                            </div>
                            <input onChange={this.handlePasswordChange} type="password" id="passwordInput" className={"form-control " + passwordValidClass} placeholder="password" aria-label="password" aria-describedby="addon-wrapping" required/>
                            <div className="invalid-feedback">Password must be between 12 and 32 characters</div>
                        </div>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="addon-wrapping">
                                    <img className="create-account-icons" src="/img/icons/key.svg" alt="key icon" title="key" />
                                </span>
                            </div>
                            <input onChange={this.handlePasswordCheckChange} type="password" id="passwordInput" className={"form-control " + passwordCheckValidClass} placeholder="re-enter password" aria-label="password" aria-describedby="addon-wrapping" required/>
                            <div className="invalid-feedback">Passwords do not match</div>
                        </div>
                        <div className="create-account-button-wrapper">
                            <button onClick={this.handleSubmit} type="submit" className="btn btn-primary btn-lg" id="sign-up-button" disabled={!this.state.verified}>Sign Up</button>
                        </div>
                        <div id="create-account-already-have">
                            <span>Already have an account?</span>
                            <a href="/log-in">Log In</a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default CreateAccount