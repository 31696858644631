import React, { Component } from "react";
import { Link } from "react-router-dom";
import './Cards.css';

export class CardGrid extends Component {
    constructor(props) {
        super(props);

        // create array of keys for prop cards
        this.cardsKeys = Object.keys(this.props.cards);
    }
    // this.props.cards[item]
    render() {
        let cardsComponents = this.cardsKeys.map((item, index) => {
            return (
                <div key={index} className="col col-sm align-self-center" align="center" id="card-col">
                    <Card img={this.props.cards[item].img} 
                          opacity={this.props.cards[item].opacity} 
                          title={this.props.cards[item].title} 
                          link={this.props.cards[item].link}/>
                </div>
            )
        })

        return (
            <div className="container-fluid" id="card-grid">
                <div className="row no-gutters">
                    {cardsComponents}
                </div>
            </div>
        )
    }
}

export class Card extends Component {
    render() {
        return (
            <Link className="card" to={this.props.link} id="card-card">
                <img className="card-img" src={this.props.img} alt={this.props.alt} />
                <h1 className="card-text">{this.props.title}</h1>
                <h1 className="card-prompt">Sign Up</h1>
            </Link>
        )
    }
}