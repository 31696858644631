import React, { useEffect, useState } from 'react';
import Loading from './components/pages/loading/Loading';
import { fireAuth } from './Fire';

export const AuthContext = React.createContext();

export const AuthProvider = ({ children, value }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [pending, setPending] = useState(true);

  useEffect(() => {
    fireAuth.onAuthStateChanged((user) => {
      setCurrentUser(user)
      setPending(false)
    });
  }, []);

  if(pending){
    return (
      <Loading/>
    )
  }

  return (
    <AuthContext.Provider
      value={{
        currentUser
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
