import React, { Component } from "react";
import './Carousel.css';

/*
--- Carousel Component ---
    expects prop 'sources' that is an array of objects with the following attributes:
        source: string link to image
        captionHead: string caption header for image
        captionP: string caption paragraph
        opacity: double value for image opacity (lower will get darker with black background)
    expects prop speed: int value that is number of miliseconds for slide speed
*/
class Carousel extends Component {
    constructor(props) {
        super(props);
        // create array of object keys from prop sources
        this.sourcesKeys = Object.keys(this.props.sources);
        // decide randomly which image will be active when page loads
        this.numberOfSources = this.sourcesKeys.length;
        this.activeImage = Math.floor(Math.random() * this.numberOfSources);
    }
    render() {
    var indicators = this.sourcesKeys.map((item, index) => {
        var isActive;
        if (index === this.activeImage) {
            isActive = "active";
        } else {
            isActive = "";
        }
        return (
            <li key={index} data-target="#carouselExampleIndicators" data-slide-to={toString(index)} className={isActive}></li>
        )
    })

    var slides = this.sourcesKeys.map((item, index) => {
        var isActive;
        if (index === this.activeImage) {
            isActive = " active";
        } else {
            isActive = "";
        }
        
        return (
            <div key={index} className={"carousel-item" + isActive}>
                <img className="d-block w-100" 
                     src={this.props.sources[item].source} 
                     alt={"slide " + toString(index)} 
                     style={{opacity: this.props.sources[item].opacity}}/>
                <section className="carousel-caption">
                    <h5 className="caption-header">{this.props.sources[item].captionHead}</h5>
                    <div className="paragraph-wrapper">
                        <p className="caption-paragraph d-none d-md-block">{this.props.sources[item].captionP}</p>
                    </div>
                </section>
            </div>
        )
    })

    return (
        <div>
            <div id="carouselExampleIndicators" 
                 className="carousel slide" 
                 data-ride="carousel"
                 data-interval={this.props.speed}>
                <ol className="carousel-indicators">
                    {indicators}
                </ol>
                <div className="carousel-inner">
                    {slides}
                </div>
                <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="sr-only">Previous</span>
                </a>
                <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="sr-only">Next</span>
                </a>
            </div>
        </div>
    );
  }
}

export default Carousel;
