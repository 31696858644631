import React, { useEffect, useState } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import { rtDatabase } from './../../Fire';
import { Redirect } from 'react-router';
import { BkgrdColorPairs, defaultProfileImg } from './../../App';
import './GroupCard.css';

function GroupCard(props) {
    const [tabsKey, setTabsKey] = useState('description');
    const [error, setError] = useState('');
    const [leaderPicURL, setLeaderPicURL] = useState(defaultProfileImg);
    const coverImg = props.group.coverImgURL === undefined ? '' : props.group.coverImgURL

    useEffect(() => {
        var leaderUid = Object.keys(props.group.leader)[0];
        rtDatabase.ref('users/' + leaderUid + '/profilePhotoURL').once('value').then(snapshot => {
            let url = snapshot.val();
            setLeaderPicURL(url === null ? defaultProfileImg : url)
        });
    })

    const pickColorPair = () => {
        return BkgrdColorPairs[Math.floor(Math.random() * BkgrdColorPairs.length)];
    }

    const handleJoinGroup = () => {
        var updates = {};
        var hasError = false;
        updates['groups/' + props.group.groupId + '/members/' + props.currUser.uid] = props.currUser.firstname + ' ' + props.currUser.lastname;
        updates['users/' + props.currUser.uid + '/groups/' + props.group.groupId] = 'member';
        rtDatabase.ref().update(updates).catch(localError => {
            hasError = true;
            setError(localError);
        });
        if (!hasError) {
            console.log("Trying to call callback");
            props.callback();
        }
    }

    var colorPair = pickColorPair();

    if (error !== '') {
        return <Redirect to={{
            pathname: '/error',
            state: { code: error.code, message: error.message }
        }} />;
    } else {
        return(
            <div className={props.class}>
                <div className="group-card-container">
                    <div
                        className="group-card-img-container"
                        style={{
                            backgroundImage: "linear-gradient(to bottom right, rgba" + colorPair[0] + ", rgba" + colorPair[1] + ")"
                        }}
                    >
                        <img alt="" className="group-card-img" src={coverImg} />
                    </div>
                    <div className="group-card-leader-img-wrapper">
                        <img alt="group leader" className="group-card-leader-img" src={leaderPicURL}/>
                    </div>
                    <div className="group-card-info-container">
                        <div className="group-card-focus-container">
                            <span className="">
                                Group Focus:
                            </span>
                            <h1 className="group-card-focus">
                                {" " + props.group.focus}
                            </h1>
                        </div>
                        <Tabs
                            id="controlled-tab-example"
                            activeKey={tabsKey}
                            onSelect={(k) => setTabsKey(k)}
                        >
                            <Tab eventKey="description" title="Description">
                                <div className="group-card-description">
                                    <div className={!props.join && props.status === 'leader' ? '' : 'd-none'}>
                                        <h3 className="group-card-description-title">Private Description</h3>
                                        <p className="group-card-description-text">
                                            {props.group.privateDescription}
                                        </p>
                                    </div>
                                    <div className={!props.join && props.status === 'leader' ? '' : 'd-none'}>
                                        <h3 className={"group-card-description-title"}>Public Description</h3>
                                    </div>
                                    <p className="group-card-description-text">
                                        {props.group.publicDescription}
                                    </p>
                                </div>
                                <p className="group-card-scroll-instruction">
                                    Scroll for more
                                </p>
                            </Tab>
                            <Tab eventKey="other-info" title="Other Info">
                                <div className="group-card-other-info-container">
                                    <div className="group-card-text">
                                        <b>Leader:</b>
                                        <span className="group-card-info-fields">
                                            {props.group.leader[Object.keys(props.group.leader)[0]]}
                                        </span>
                                    </div>
                                    <div className="group-card-text">
                                        <b>Members:</b>
                                        <span className="group-card-info-fields">
                                            {Object.keys(props.group.members).length + " / " + props.group.maxCap}
                                        </span>
                                    </div>
                                    <div className="group-card-text">
                                        <b>Location:</b>
                                        <span className="group-card-info-fields">
                                            {props.group.location}
                                        </span>
                                    </div>
                                    <div className="group-card-text">
                                        <b>Meeting Time:</b>
                                        <span className="group-card-info-fields">
                                            {props.group.time}
                                        </span>
                                    </div>
                                    <div className="group-card-text">
                                        <b>Active Until:</b>
                                        <span className="group-card-info-fields">
                                            {String(props.group.endDate).replace(/^(\w*\s\w*\s\d*)(\s\d*)(.*)$/,'$1,$2')}
                                        </span>
                                    </div>
                                    <div className={"group-card-text " + props.join ? '' : 'd-none'}>
                                        <b>Visibility:</b>
                                        <span className="group-card-info-fields">
                                            {props.group.private ? 'Private' : 'Public'}
                                        </span>
                                    </div>
                                </div>
                                <p className="group-card-scroll-instruction">
                                    Scroll for more
                                </p>
                            </Tab>
                        </Tabs>
                        <div className={props.join ? '' : 'd-none'}>
                            <div className={"col-md group-card-btn-wrapper-wrapper"}>
                                <div className="group-card-btn-wrapper">
                                    <button
                                        type="button"
                                        onClick={handleJoinGroup}
                                        className="custom-btn btn-6"
                                        // style={{background: "radial-gradient(circle, rgba" + colorPair[0] + " 0%, rgba" + colorPair[1] + " 100%)"}}
                                    >
                                        <span className="group-card-btn-text">Join Group</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default GroupCard
