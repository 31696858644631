const Testemonials = [
    {
        img: './img/headshots/trevor-haugen.jpg',
        verticalOffset: "20%",
        title: 'Trevor Haugen',
        text: [ 
            <>
                "Being in a small group was one of the best ways to develop my 
                relationship with God, as well as develop God centered friendships 
                here at WWU."
            </>,
            <>
                "Small groups for me, was the perfect getaway from the stress and 
                craziness of college. It helped me to find the time for God when I 
                needed it most!" 
            </>
        ]
    },
    {
        img: './img/headshots/emily-ellis.jpg',
        verticalOffset: "60%",
        title: 'Emily Ellis',
        text: [
            <>
                "There is something beautiful that happens in a small group community. I had the 
                opportunity to be a small group leader for a few quarters my junior year. 
                The people in the group didn't know each other at first, but as the weeks went 
                on we became close friends. It was so wonderful gathering for an hour, once a week, 
                and reflecting how God was moving in our lives. I grew so much that year and learned 
                so much from the people in that group." 
            </>
        ]
    },
    {
        img: './img/headshots/rachel-barlow.jpg',
        verticalOffset: "40%",
        title: 'Rachel Barlow',
        text: [
            <>
                "When I was in a small group last year I loved the surprise of having the outcomes 
                differ from my expectations. I thought that small groups would "blow my mind," with 
                concepts that were unique and transformative every time that we would meet. Rather, 
                I found that my soul was not craving epiphanies, but rather fellowship. Having a 
                consistent community of people that are focused on Christ and on love was a powerful 
                thing."
            </>,
            <>
                "Sometimes after the worship part of small groups ended, that's when I felt the 
                strongest presence of the Holy Spirit. The post-worship conversations, fellowships, 
                and just hanging out was my favorite aspect of small groups."
            </>
        ]
    },
    {
        img: './img/mask/default.jpg',
        verticalOffset: "10%",
        title: 'Julianna Flores',
        text: [
            <>
                "The small group experience was fun for me. I always looked forward to the next 
                meeting because we would tell stories or laugh at relatable moments from different 
                mission experiences. It was nice being able to open up about all experiences 
                including the struggles to people who understood what you went through because 
                they went through the same thing."
            </>
        ]
    }
]

export default Testemonials