import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './Coaster.css';

/*
    Coaster
    - A simple circle image with variable width.
    
    Props
        name: name of the person pictured in the coaster.
        img: link to the image displayed.
        link: link activated when clicked
        offset: string in the form '50% 50%' that denotes the 
                x and y offset from top left. 50-50 is centered.
        id: custom id if needed for css styling. defualt is ""
*/
class Coaster extends Component {
    constructor(props) {
        super(props);
        // save the first name of the person pictured
        let space = this.props.name.indexOf(' ');
        this.firstName = this.props.name.slice(0, space);

        this.id = this.props.id;
        if (this.id === undefined) {
            this.id = "";
        }
        console.log("id: " + this.id);
    }
    render() {
        if (this.props.isLink) {
            return (
                <Link className="coaster coaster-with-link card" id={this.id} to={this.props.link}>
                    <img className="coaster-img" src={this.props.img} alt={this.props.name} 
                        style={{ objectPosition: this.props.offset }}/>
                    <h1 className="coaster-prompt">Meet {this.firstName}!</h1>
                </Link>
            )
        } else {
            return (
                <div className="coaster card" id={this.id}>
                    <img className="coaster-img" src={this.props.img} alt={this.props.name} 
                        style={{ objectPosition: this.props.offset }}/>
                </div>
            )
        }
    }
}

export default Coaster