import React, { Component } from 'react';
import { rtDatabase, fireAuth } from './../../../Fire';
import { Redirect } from 'react-router';
import Hero from './../../hero/Hero';
import NavBar from './../../nav-bar/NavBar';
import Loading from './../loading/Loading';
import GroupCard from './../../group-card/GroupCard';
import './YourGroups.css';

class YourGroups extends Component {
    constructor(props) {
        super(props);
        this.state={
            groups: '',
            currUser: '',
            error: ''
        }
    }

    componentDidMount() {
        // load group objects
        rtDatabase.ref('groups/').once('value').then(snapshot => {
            var groups = snapshot.val();
            this.setState({groups: groups})
        }).catch(error => {
            this.setState({error: error})
        })

        // load current user
        if (fireAuth.currentUser != null) {
            var uid = fireAuth.currentUser.uid;
            rtDatabase.ref('users/' + uid).once('value').then(snapshot => {
                var user = snapshot.val();
                this.setState({
                    currUser: user
                });
            }).catch(error => {
                this.setState({error: error})
            })
        }          
    }

    createGroupCards = groupid => {
        console.log("map key: " + groupid);
        return (
            <div key={groupid}>
                <GroupCard
                    class="group-card your-groups-group-card"
                    group={this.state.groups[groupid]}
                    currUser={this.state.currUser}
                    join={false}
                    status={this.state.currUser.groups[groupid]}
                />
            </div>
        )
    }

    render() {
        if (this.state.error !== '') {
            return <Redirect to={{
                pathname: '/error',
                state: { code: this.state.error.code, message: this.state.error.message }
            }} />;
        } else {
            if (fireAuth.currentUser === null) {
                return <Redirect to={{
                    pathname: '/log-in',
                    state: { redirect: '/join-group'}
                }} />;
            } else if (this.state.groups === '' || this.state.currUser === '') {
                return <Loading />;
            } else {
                var pendingGroups = [];
                var leaderGroups = [];
                var memberGroups = [];
                if (this.state.currUser.groups !== undefined) {
                    pendingGroups = Object.keys(this.state.currUser.groups).filter(groupid => {
                        console.log("filter key: " + groupid);
                        var isLeader = this.state.currUser.groups[groupid] === 'leader';
                        var isPending = !this.state.groups[groupid].approved;
                        return isLeader && isPending;
                    }).map(this.createGroupCards);

                    leaderGroups = Object.keys(this.state.currUser.groups).filter(groupid => {
                        var isLeader = this.state.currUser.groups[groupid] === 'leader';
                        var isApproved = this.state.groups[groupid].approved;
                        return isLeader && isApproved;
                    }).map(this.createGroupCards);

                    memberGroups = Object.keys(this.state.currUser.groups).filter(groupid => {
                        var isMember = this.state.currUser.groups[groupid] === 'member';
                        var isApproved = this.state.groups[groupid].approved;
                        return isMember && isApproved;
                    }).map(this.createGroupCards);
                }
                return (
                    <div>
                        <NavBar />
                        <Hero
                            img="./img/camping/gr-crew.jpg"
                            imgVerticalOffset="40%"
                            imgOpacity="0.5"
                            class="your-groups-hero"
                            title="Your Groups"
                        />
                        <div className="your-groups-body">
                            <div className={pendingGroups.length === 0 ? 'd-none' : ''}>
                                <div className="your-pending-groups-container">
                                    <div className="your-groups-section-text-container">
                                        <h1 className="your-groups-section-title">Your Pending Groups</h1>
                                        <p className="your-groups-section-text">
                                            You created these groups but they're still waiting approval.
                                        </p>
                                    </div>
                                    <div className="your-groups-card-centerer">
                                        <div className="your-groups-card-container">{pendingGroups}</div>
                                    </div>
                                </div>
                            </div>
                            <div className={leaderGroups.length === 0 ? 'd-none' : ''}>
                                <div className="your-leader-groups-container">
                                    <div className="your-groups-section-text-container">
                                        <h1 className="your-groups-section-title">Groups You Lead</h1>
                                        <p className="your-groups-section-text">
                                            These are the groups you lead, aren't they beautiful?
                                        </p>
                                    </div>
                                    <div className="your-groups-card-centerer">
                                        <div className="your-groups-card-container">{leaderGroups}</div>
                                    </div>
                                </div>
                            </div>
                            <div className={memberGroups.length === 0 ? 'd-none' : ''}>
                                <div className="your-member-groups-container">
                                   <div className="your-groups-section-text-container">
                                        <h1 className="your-groups-section-title">Groups You're In</h1>
                                        <p className="your-groups-section-text">
                                            These are the groups you're a member of! Aren't they amazing? Bet they're a blast.
                                        </p>
                                    </div>
                                    <div className="your-groups-card-centerer">
                                        <div className="your-groups-card-container">{memberGroups}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        }
    }
}

export default YourGroups
