import React, { Component } from 'react';
import { rtDatabase, fireAuth, fireStorage } from './../../../Fire';
import NavBar from './../../nav-bar/NavBar';
import Loading from './../loading/Loading';
import { Redirect } from 'react-router';
import { Table, Button } from 'react-bootstrap';
import PicUploadModal from './../../pic-upload-modal/PicUploadModal';
import { defaultProfileImg } from './../../../App';
import './Profile.css';

class Profile extends Component {
    constructor(props) {
        super(props)
        this.state = {
            userInfo: '',
            uid: fireAuth.currentUser.uid,
            error: '',
            file: '',
            showPicUploadModal: false,
            profilePhotoURL: ''
        }

    }

    componentDidMount() {
        rtDatabase.ref('users/' + this.state.uid).once('value').then(snapshot => {
            this.setState({
                userInfo: snapshot.val(),
                profilePhotoURL: snapshot.val().profilePhotoURL === undefined ? defaultProfileImg : snapshot.val().profilePhotoURL
            })
        }).catch(error => {
            this.setState({error: error})
        })
    }

    handleEditProfilePhotoClick = event => {
        event.preventDefault();
        console.log('clicked')
    }

    handleConfirmUpload = () => {
        fireStorage.ref('images').put(this.state.file).then(() => {
            console.log("uploaded a file")
        }).catch(error => {
            console.log(error);
        })
    }

    handleShowModal = stateProp => {
        this.setState({
            [stateProp]: true
        })
    }

    handleHideModal = stateProp => {
        this.setState({
            [stateProp]: false
        })
    }

    handleLinkReturn = link => {
        this.setState({profilePhotoURL: link})
    }


    render() {
        if (this.state.error !== '') {
            return <Redirect to={{
                pathname: '/error',
                state: { code: this.state.error.code, message: this.state.error.message }
            }} />;
        } else if (this.state.userInfo === '') {
            return (
                <div>
                    <Loading />
                </div>
            )
        } else {
            let user = this.state.userInfo;
            return (
                <>
                    <NavBar />
                    <div data-testid='profile-page' className="profile-page-container">
                        <div className="profile-img-container">
                            <div className="profile-img-background">
                                <div className="profile-img-placeholder-text">
                                    {user.firstname.charAt(0)}{user.lastname.charAt(0)}
                                </div>
                                <img className="profile-img" src={this.state.profilePhotoURL} alt='profile'/>
                                <button onClick={() => this.handleShowModal("showPicUploadModal")} className="profile-img-edit-hover">
                                    <i className="bi-pencil-fill"/>
                                </button>
                            </div>
                            <p className="profile-name">{user.firstname + ' ' + user.lastname}</p>
                        </div>
                        <div className="profile-table-container">
                            <Table responsive="sm">
                                <tbody>
                                    <tr>
                                        <td className="profile-table-text profile-left-col">Date Added</td>
                                        <td className="profile-table-text">{user.dateAdded.month + '/' + user.dateAdded.day + '/' + user.dateAdded.year}</td>
                                    </tr>
                                    <tr>
                                        <td className="profile-table-text profile-left-col">Phone</td>
                                        <td className="profile-table-text">{user.phone}</td>
                                    </tr>
                                    <tr>
                                        <td className="profile-table-text profile-left-col">email</td>
                                        <td className="profile-table-text">{user.email}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                        <div className="profile-edit-btn-container">
                            <div className="profile-edit-btn-wrapper">
                                <Button variant="primary">Edit</Button>
                            </div>
                        </div>
                    </div>
                    <PicUploadModal
                        title="Upload Profile Photo"
                        onHide={() => { this.handleHideModal("showPicUploadModal")}}
                        show={this.state.showPicUploadModal}
                        folder="profilePhotos"
                        name={fireAuth.currentUser.uid}
                        linkSaveLocation={'users/' + fireAuth.currentUser.uid + '/profilePhotoURL'}
                        linkReturn={this.handleLinkReturn}
                        default={defaultProfileImg}
                    />
                </>
            )
        }
    }
}

export default Profile
