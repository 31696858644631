import React, { Component } from 'react';
import './DataTable.css';
import { Table, Button } from 'react-bootstrap';

class DataTable extends Component {

    render() {
        return (
            <div>
                <Table striped>
                    <thead>
                        <tr>
                            {this.props.headers.map(header => {
                                return <th>{header}</th>
                            })}
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(this.props.data).filter(key => key !== "placeholder" && this.props.criteria(this.props.data[key])).map(key => {
                            var item = this.props.data[key];
                            return (
                                <tr>
                                    {this.props.dataFields.map(field => {
                                        return <td>{item[field]}</td>
                                    })}
                                    <td className="data-table-options-container">
                                        {this.props.buttons.map(btn => {
                                            return (
                                                <div className="data-table-option-btn-container">
                                                    <Button 
                                                        variant={btn.variant} 
                                                        size="sm" 
                                                        onClick={() => {btn.handleClick(key)}} 
                                                        type="button" title={btn.title}
                                                    >
                                                        <i className={btn.icon}/>
                                                    </Button>
                                                </div>
                                            )
                                        })}
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>

            </div>
        )
    }
}

export default DataTable
