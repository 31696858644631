import React, { Component } from 'react';
import { rtDatabase, fireAuth, fireStorage } from './../../../../Fire';
import firebase from 'firebase';
import DatePicker from "react-datepicker";
import TimeInputPolyfill from 'react-time-input-polyfill'
import Hash from 'object-hash';
import { Redirect } from 'react-router-dom';
import BtnSpinner from './../../../btn-spinner/BtnSpinner';
import "react-datepicker/dist/react-datepicker.css";
import './StartGroupForm.css';

window.onbeforeunload = () => {
    console.log("Not letting you refresh");
}

class StartGroupForm extends Component {
    constructor(props, context) {
        super(props)

        var currDate = new Date();

        this.focusLimit = 30;
        this.maxLimit = 20;
        this.checked = false;
        this.groupId = this.props.approving ? this.props.group.groupId : Hash(currDate, {algorithm: "sha1"});
        var group = this.props.group;
        var stateObj = {};
        Object.assign(stateObj, {
            focus: '',
            focusError: '',
            showFocus: 'd-none',
            firstname: this.props.currUser.firstname,
            lastname: this.props.currUser.lastname,
            nameError: '',
            privateDescription: '',
            privateDescriptionError: '',
            private: true,
            privateError: '',
            endDate: '',
            endDateError: '',
            location: '',
            locationError: '',
            time: '',
            timeError: '',
            maxCap: '',
            maxCapError: '',
            publicDescription: '',
            publicDescriptionError: '',
            submitError: '',
            groupFocusTypes: this.props.focusTypes,
            error: '',
            showModal: false,
            file: null,
            filePreviewURL: this.props.approving ? this.props.group.coverImgURL : null,
            submitting: false
        })
        if (group !== undefined) {
            Object.assign(stateObj, {
                focus: group.focus,
                showFocus: this.props.newType ? '' : 'd-none',
                firstname: group.leader[Object.keys(group.leader)[0]].replace(/([a-zA-Z]*)(\s)([a-zA-Z]*)/, '$1'),
                lastname: group.leader[Object.keys(group.leader)[0]].replace(/([a-zA-Z]*)(\s)([a-zA-Z]*)/, '$3'),
                privateDescription: group.privateDescription,
                private: group.private,
                endDate: new Date(group.endDate),
            })
            if (!group.private) {
                Object.assign(stateObj, {
                    location: group.location,
                    time: group.time,
                    maxCap: group.maxCap,
                    publicDescription: group.publicDescription,
                })
            }    
        }
        this.state = stateObj;
    }

    setFocus = event => {
        if (event.target.value.length <= this.focusLimit) {
            this.setState({
                focus: event.target.value,
                focusError: ''
            })
        } else {
            this.setState({
                focusError: 'Focus can not be longer than ' + this.focusLimit + ' characters.'
            })
        }
    }

    showFocus = () => {
        this.setState(currentState => {
            if (currentState.showFocus === 'd-none') {
                return {showFocus: ''};
            } else {
                return {showFocus: 'd-none', focus: ''};
            }
        });
    }

    capitalizeStr(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    isName(str) {
        var regex = /^[a-zA-Z-]*$/;
        return regex.test(str);
    }
    
    setFirstname = event => {
        var name = event.target.value;
        if (this.isName(name)) {
            this.setState({
                firstname: this.capitalizeStr(name),
                nameError: ''
            })
        } else {
            this.setState({
                firstname: this.capitalizeStr(name),
                nameError: 'Must enter a valid name'
            })
        }
    }

    setLastname = event => {
        var name = event.target.value;
        if (this.isName(name)) {
            this.setState({
                lastname: this.capitalizeStr(name),
                nameError: ''
            })
        } else {
            this.setState({
                lastname: this.capitalizeStr(name),
                nameError: 'Must enter a valid name'
            })
        }
    }

    setPrivateDescription = event => {
        this.setState({
            privateDescription: event.target.value,
            privateDescriptionError: ''
        });
    }

    privateClass = () => {
        if (this.state.private === '' || this.state.private === true) {
            return 'd-none';
        } else {
            return '';
        }
    }

    privateClicked = event => {
        this.setState({
            private: true
        })
    }
    
    publicClicked = event => {
        this.setState({
            private: false
        })
    }

    formatDate(date) {
        return date.replace(/(\d\d)\/(\d\d)\/(\d\d\d\d)/, '$3/$1/$2')
    }

    setEndDate = date => {
        // date format example: Tue Apr 13 2021 00:00:00 GMT-0700 (PDT)
        var currDate = new Date();
        var futureDate = new Date(2100, 0, 1);
        if (date < currDate) {
            this.setState({
                endDate: date,
                endDateError: "Must enter a future date"
            })    
        } else if (date > futureDate) {
            this.setState({
                endDate: date,
                endDateError: 'Must enter a realistic end date'
            })
        } else {
            this.setState({
                endDate: date,
                endDateError: ''
            })
        }
    }

    setLocation = event => {
        this.setState({
            location: event.target.value,
            locationError: ''
        })
    }

    setTime = event => {
        this.setState({
            time: event.value,
            timeError: ''
        })
    }

    isNum(str) {
        let regex = /^\d*$/;
        return regex.test(str);
    }

    setMaxCap = event => {
        var str = event.target.value;
        if (str === '') {
            this.setState({
                maxCap: '',
                maxCapError: ''
            })
        } else if (this.isNum(str)) {
            if (parseInt(str) <= this.maxLimit) {
                this.setState({
                    maxCap: str,
                    maxCapError: ''
                })
            } else {
                this.setState({
                    maxCap: str,
                    maxCapError: 'Max Capacity cannot exceed ' + this.maxLimit + ' people.'
                })
            }
        } else {
            this.setState({
                maxCap: '',
                maxCapError: 'You must enter a number'
            })
        }
    }

    setPublicDescription = event => {
        this.setState({
            publicDescription: event.target.value,
            publicDescriptionError: ''
        })
    }

    getErrorClass(errorMsg) {
        return errorMsg === '' ? '' : 'is-invalid';
    }

    getDate = () => {
        var d = new Date();
        var date = {
            month: d.getMonth() + 1,
            day: d.getDate(),
            year: d.getFullYear()
        }
        return date
    }

    handleSubmit = () => {
        var valid = true;
        var stateKeys = Object.keys(this.state);
        var newState = {};
        stateKeys.forEach(key => {
            if (/Error/.test(key)) {
                // looking at error messages in state
                if (this.state[key] !== '' && key !== 'submitError') {
                    valid = false;
                }
            } else {
            // looking at input values in state
                // if group is private
                if (this.state.private === true|| this.state.private === '') {
                    // look through private fields
                    if (key !== 'location' && key !== 'time' &&
                        key !== 'maxCap' && key !== 'publicDescription') {
                        if (this.state[key] === '') {
                            // if input value is empty, add error to newState
                            if (key === 'firstname' || key === 'lastname') {
                                // handle firstname/lastname naming convention
                                newState.nameError = 'This field cannot be blank.';
                                valid = false;
                            } else {
                                newState[key + 'Error'] = 'This field cannot be blank.';
                                valid = false;
                            }
                        } else {
                        // remove error from state
                            if (key === 'firstname' || key === 'lastname') {
                                newState.nameError = '';
                            } else {
                                newState[key + 'Error'] = '';
                            }
                        }
                    }
                } else {
                // group is public
                    // look through all fields
                    if (this.state[key] === '') {
                        // if input value is empty, add error to newState
                        if (key === 'firstname' || key === 'lastname') {
                            // handle firstname/lastname naming convention
                            newState.nameError = 'This field cannot be blank.';
                            valid = false;
                        } else {
                            newState[key + 'Error'] = 'This field cannot be blank.';
                            valid = false;
                        }
                    } else {
                        // remove error from state
                        if (key === 'firstname' || key === 'lastname') {
                            newState.nameError = '';
                        } else {
                            newState[key + 'Error'] = '';
                        }
                    }
                }
            }
        })
        if (!valid) {
            newState.submitError = 'Some required fields were not entered correctly';
            this.checked = true;
            this.setState(newState, () => {
                // final sweep
                var sweepValid = true;
                if (this.checked) {
                    stateKeys.forEach(key => {
                        if (/Error/.test(key) && this.state[key] !== '' && key !== 'submitError') {
                            sweepValid = false;
                        }
                    });
                }
                if (sweepValid) {
                    this.handleSuccess();
                }
            });    
        } else {
            this.handleSuccess();
        }
    }

    handleSuccess = () => {
        this.setState({
            submitError: '',
            submitting: true
        }, () => {
            // update database here

            // make unique group id, date added, and leader/member objects
            var dateAdded;
            var leader
            var members
            if (!this.props.approving) {
                dateAdded = this.getDate();
                leader = {
                    [this.props.currUser.uid]: this.state.firstname + ' ' + this.state.lastname
                };
                members = {
                    [this.props.currUser.uid]: this.state.firstname + ' ' + this.state.lastname
                }
            } else {
                dateAdded = this.props.group.dateAdded;
                leader = this.props.group.leader;
                members = this.props.group.members;
            }

            // make object to save to database
            var saveObject = {};
            Object.assign(saveObject, {
                focus: this.state.focus,
                leader: leader,
                endDate: String(this.state.endDate),
                privateDescription: this.state.privateDescription,
                private: this.state.private,
                groupId: this.groupId,
                approved: this.props.approving,
                dateAdded: dateAdded,
                members: members,
                coverImgURL: this.state.filePreviewURL === undefined ? null : this.state.filePreviewURL
            })
            if (!this.state.private) {
                Object.assign(saveObject, {
                    location: this.state.location,
                    time: this.state.time,
                    maxCap: this.state.maxCap,
                    publicDescription: this.state.publicDescription
                })
            }

            var updates = {};
            // save new group data
            updates['groups/' + this.groupId] = saveObject
            // save group to leader user
            updates['users/' + this.props.currUser.uid + '/groups/' + this.groupId] = 'leader';
            //  save new focus type if one was created and if we are approving the group 
            //  (only time newType is passed in)
            if (this.props.newType) {
                updates['groupFocusTypes/' + this.state.focus] = true;
            }
            rtDatabase.ref().update(updates).then(() => {
                if (this.state.file !== null) {
                    // All done, move on to the final step
                    console.log("Trying to upload a file");
                    this.handleCoverImgUpload();
                } else {
                    this.setState({submitting: false})
                    this.props.doneCallback();
                }
            }).catch(error => {
                this.setState({error: error})
            })
        
        })
    }

    handleCoverImgUpload = () => {
        var uploadTask = fireStorage.ref().child('images/groupCoverPhotos/' + this.groupId).put(this.state.file);

        // Register three observers:
        // 1. 'state_changed' observer, called any time the state changes
        // 2. Error observer, called on failure
        // 3. Completion observer, called on successful completion
        uploadTask.on('state_changed', 
            (snapshot) => {
                // Observe state change events such as progress, pause, and resume
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log('Upload is ' + progress + '% done');
                switch (snapshot.state) {
                    case firebase.storage.TaskState.PAUSED: // or 'paused'
                        console.log('Upload is paused');
                        break;
                    case firebase.storage.TaskState.RUNNING: // or 'running'
                        console.log('Upload is running');
                        break;
                    default:
                        break;
                }
            }, 
            (error) => {
                // Handle unsuccessful uploads
                // A full list of error codes is available at
                // https://firebase.google.com/docs/storage/web/handle-errors
                switch (error.code) {
                    case 'storage/unauthorized':
                        // User doesn't have permission to access the object
                        console.log("user doesn't have permission")
                        break;
                    case 'storage/canceled':
                        // User canceled the upload
                        console.log("user cancelled upload")
                        break;
                    case 'storage/unknown':
                        // Unknown error occurred, inspect error.serverResponse
                        console.log("unknown error")
                        break;
                    default:
                        break;
                }
            }, 
            () => {
                // Handle successful uploads on complete
                // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                    console.log('File available at', downloadURL);
                    rtDatabase.ref('groups/' + this.groupId + '/coverImgURL').set(downloadURL).then(() => {
                        console.log('cover image link save success')
                        // We all done, close this modal
                        this.props.doneCallback();
                    }).catch(error => {
                        console.error("There was an error with uploading the image link.", error)
                    });
                }).catch(error => {
                    console.error("There was an error with retrieving the image link", error)
                })
            }
        );
    }

    handleFileChange = event => {
        let file = event.target.files[0];
        this.setState({
            file: file,
            filePreviewURL: URL.createObjectURL(file)
        });
    }

    render() {
        if (this.state.error !== '') {
            return <Redirect to={{
                pathname: '/error',
                state: { code: this.state.error.code, message: this.state.error.message }
            }} />;
        }

        if (fireAuth.currentUser == null) {
            return <Redirect to='/' />;
        }

        var groupFocusTypes = this.state.groupFocusTypes === null ? {} : this.state.groupFocusTypes;
        console.log("file url:", this.state.filePreviewURL)
        return (
            <>
                <div className="col">
                    <label className="form-label">Group Cover Photo</label>
                    <p className="form-item-description">
                        If left blank, your group's cover photo will be a simple color gradient. Not bad, but not very personal.
                    </p>
                    <div className={this.state.filePreviewURL === undefined || this.state.filePreviewURL === null ? 'd-none' : ''}>
                        <img className="start-group-form-cover-img" src={this.state.filePreviewURL} alt="preview" />
                    </div>
                    {/* <img className="start-group-form-cover-img" src="https://i.imgur.com/U3H7RBE.jpg" alt="" /> */}
                    <input type="file" accept=".jpg, .png" onChange={this.handleFileChange} />
                </div>
                <div className="col">
                    <label className="form-label">Group Focus</label>
                    <p className="form-item-description">
                        Music, Bible study, mountain biking, jogging, etc
                    </p>
                    <select 
                        onChange={this.setFocus} 
                        value={this.state.focus} 
                        className={"form-control " + this.getErrorClass(this.state.focusError)} 
                        disabled={!(this.state.showFocus === 'd-none')}
                        style={{cursor: 'pointer'}}
                    >
                        <option>Choose Group Focus</option>
                        {Object.keys(groupFocusTypes).map(key => {
                            return <option>{key}</option>;
                        })}
                    </select>
                    <button type="button" className="btn btn-link" onClick={this.showFocus}>
                        {this.state.showFocus === 'd-none' ?
                            "Create new focus category" :
                            "Choose existing focus category"
                        }
                    </button>
                    <div className={this.state.showFocus}>
                        <div className={this.props.approving ? 'd-none' : ''}>
                            <p className={"form-item-description "}>
                                This will be listed as a focus category for creating future groups,
                                so please be sure to spell everything correctly and capitalize each word.
                            </p>
                        </div>
                        <div className={this.props.approving ? '' : 'd-none'}>
                            <p className={"form-item-description "} style={{color: "red"}}>
                                This is a new focus category, so approving this group with this category will create
                                a new category option for people to use.
                            </p>
                        </div>
                        <input type="text" className={"form-control " + this.getErrorClass(this.state.focusError)} placeholder="New Focus Category" onChange={this.setFocus} value={this.state.focus}/>
                        <div className="focus-char-counter-container">
                            <div className={"focus-char-counter"}>
                                <p>{this.focusLimit - this.state.focus.length}</p>
                            </div>
                        </div>
                        <div className="invalid-feedback">{this.state.focusError}</div>
                    </div>
                    <div className="invalid-feedback">{this.state.focusError}</div>
                </div>
                <div className="col">
                    <label className="form-label" id="group-leader-name-label">Group Leader Name</label>
                    <div className="input-group start-group-input-group mb-3">
                        <input disabled type="text" className={"form-control " + this.getErrorClass(this.state.nameError)} id="name-input-group" placeholder="First Name" aria-label="First name" value={this.state.firstname}/>
                        <input disabled type="text" className={"form-control " + this.getErrorClass(this.state.nameError)} placeholder="Last Name" aria-label="Last name" value={this.state.lastname}/>
                        <div className="invalid-feedback">{this.state.nameError}</div>
                    </div>
                </div>
                <div className="col">
                    <div className="mb-3">
                        <label htmlFor="start-group-private-description" className="form-label">Private Description</label>
                        <p className="form-item-description">
                            This will be seen by the small group directors and should indicate
                            the mission and goals of your small group.
                        </p>
                        <textarea 
                            className={"form-control " + this.getErrorClass(this.state.privateDescriptionError)} 
                            id="start-group-private-description" 
                            rows="3" 
                            onChange={this.setPrivateDescription} 
                            value={this.state.privateDescription} 
                        />
                        <div className="invalid-feedback">{this.state.privateDescriptionError}</div>
                    </div>
                </div>
                <div className="col">
                    <div className="input-group start-group-input-group">
                        <label className={"form-label " + this.getErrorClass(this.state.endDateError)}>End Date for Group</label>
                        <div className="start-group-date-wrapper">
                            <DatePicker
                                className="start-group-date-picker"
                                selected={this.state.endDate}
                                onChange={this.setEndDate}
                            />
                        </div>
                        <div className="invalid-feedback">{this.state.endDateError}</div>
                    </div>
                </div>
                <div className="col">
                    <label className={"form-label " + this.getErrorClass(this.state.privateError)}>Group Privacy</label>
                    <div className="form-check">
                        <input defaultChecked={this.state.private} className="form-check-input" type="radio" name="flexRadioDefault" id="startGroupPrivateRadio" onClick={this.privateClicked}/>
                        <label className="form-check-label" htmlFor="flexRadioDefault1">
                            Private Group
                        </label>
                    </div>
                    <div className="form-check">
                        <input defaultChecked={!this.state.private} className="form-check-input" type="radio" name="flexRadioDefault" id="startGroupPublicRadio" onClick={this.publicClicked}/>
                        <label className="form-check-label" htmlFor="flexRadioDefault2">
                            Public Group
                        </label>
                    </div>
                    <div className="invalid-feedback">{this.state.privateError}</div>
                </div>
                <div className={"start-public-group-questions " + this.privateClass()}>
                    <div className="col">
                        <label className="form-label">Meeting Location</label>
                        <input type="text" className={"form-control " + this.getErrorClass(this.state.locationError)} placeholder="Location" onChange={this.setLocation} value={this.state.location} />
                        <div className="invalid-feedback">{this.state.locationError}</div>
                    </div>
                    <div className="col">
                        <label className={"form-label " + this.getErrorClass(this.state.timeError)}>Weekly Meeting Time</label>
                        <TimeInputPolyfill 
                            className="start-group-time-picker" 
                            value={this.state.time}
                            label="the label" 
                            onChange={this.setTime}
                        />
                        <div className="invalid-feedback">{this.state.timeError}</div>
                    </div>
                    <div className="col">
                        <label className="form-label">Max Capacity</label>
                        <p className="form-item-description">
                            Maximum number of members your group can have, including group leaders
                        </p>
                        <input type="text" className={"form-control " + this.getErrorClass(this.state.maxCapError)} placeholder="member count" onChange={this.setMaxCap} value={this.state.maxCap} />
                        <div className="invalid-feedback">{this.state.maxCapError}</div>
                    </div>
                    <div className="col">
                        <div className="mb-3">
                            <label htmlFor="start-group-private-description" className={"form-label " + this.getErrorClass(this.state.publicDescriptionError)}>Public Description</label>
                            <p className="form-item-description">
                                This will be seen by people looking for a small group to join.
                            </p>
                            <textarea 
                                className="form-control" 
                                id="start-group-private-description" 
                                rows="3" 
                                onChange={this.setPublicDescription} 
                                value={this.state.publicDescription}
                            />
                            <div className="invalid-feedback">{this.state.publicDescriptionError}</div>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-md-center">
                    <div className="col-md-3 start-group-submit-wrapper">
                        <button type="button" className="btn btn-dark" onClick={this.handleSubmit} disabled={this.state.submitting}>
                            <BtnSpinner
                                message={this.props.editing ? 'Save Edits' : this.props.approving ? 'Approve Group' : 'Submit'}
                                active={this.state.submitting}
                            />
                        </button>
                    </div>
                    <p className={"start-group-submit-error-msg"}>{this.state.submitError}</p>
                </div>
            </>
        )
    }
}

export default StartGroupForm