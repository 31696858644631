export const LeaderData = {
    lukeIrvine: {
        name: "Luke Irvine",
        tagline: "There's a snake in my boot!",
        groupTypes: {
            music: true
        },
        img: "/img/mask/luke.jpg",
        link: "/leaders/lukeIrvine",
        offset: "50% 20%",
        bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do " +
                     "eiusmod tempor incididunt ut labore et dolore magna aliqua. Elit " +
                     "at imperdiet dui accumsan. Sed ullamcorper morbi tincidunt ornare " +
                     "massa eget egestas. Ante metus dictum at tempor commodo ullamcorper " +
                     "a lacus vestibulum. Facilisis volutpat est velit egestas dui. Id " +
                     "cursus metus aliquam eleifend mi in. Nulla pharetra diam sit amet " +
                     "nisl suscipit adipiscing. Congue quisque egestas diam in arcu cursus " +
                     "euismod quis viverra. Sed vulputate odio ut enim blandit volutpat. " +
                     "Consectetur purus ut faucibus pulvinar elementum integer. In hac " +
                     "habitasse platea dictumst quisque sagittis. Odio tempor orci dapibus " +
                     "ultrices."
    },
    amandaHarrison: {
        name: "Amanda Harrison",
        tagline: "Leader tagline",
        groupTypes: {
            music: true
        },
        img: "/img/mask/amanda.jpg",
        link: "/leaders/amandaHarrison",
        offset: "50% 20%",
        bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do " +
                     "eiusmod tempor incididunt ut labore et dolore magna aliqua. Elit " +
                     "at imperdiet dui accumsan. Sed ullamcorper morbi tincidunt ornare " +
                     "massa eget egestas. Ante metus dictum at tempor commodo ullamcorper " +
                     "a lacus vestibulum. Facilisis volutpat est velit egestas dui. Id " +
                     "cursus metus aliquam eleifend mi in. Nulla pharetra diam sit amet " +
                     "nisl suscipit adipiscing. Congue quisque egestas diam in arcu cursus " +
                     "euismod quis viverra. Sed vulputate odio ut enim blandit volutpat. " +
                     "Consectetur purus ut faucibus pulvinar elementum integer. In hac " +
                     "habitasse platea dictumst quisque sagittis. Odio tempor orci dapibus " +
                     "ultrices."
    },
    audreyTurner: {
        name: "Audrey Turner",
        tagline: "Leader tagline",
        groupTypes: {
            music: true
        },
        img: "/img/mask/audrey.jpg",
        link: "/leaders/audreyTurner",
        offset: "50% 20%",
        bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do " +
                     "eiusmod tempor incididunt ut labore et dolore magna aliqua. Elit " +
                     "at imperdiet dui accumsan. Sed ullamcorper morbi tincidunt ornare " +
                     "massa eget egestas. Ante metus dictum at tempor commodo ullamcorper " +
                     "a lacus vestibulum. Facilisis volutpat est velit egestas dui. Id " +
                     "cursus metus aliquam eleifend mi in. Nulla pharetra diam sit amet " +
                     "nisl suscipit adipiscing. Congue quisque egestas diam in arcu cursus " +
                     "euismod quis viverra. Sed vulputate odio ut enim blandit volutpat. " +
                     "Consectetur purus ut faucibus pulvinar elementum integer. In hac " +
                     "habitasse platea dictumst quisque sagittis. Odio tempor orci dapibus " +
                     "ultrices."
    },
    auroraGault: {
        name: "Aurora Gault",
        tagline: "Leader tagline",
        groupTypes: {
            spiritual: true
        },
        img: "/img/mask/aurora.jpg",
        link: "/leaders/auroraGault",
        offset: "50% 20%",
        bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do " +
                     "eiusmod tempor incididunt ut labore et dolore magna aliqua. Elit " +
                     "at imperdiet dui accumsan. Sed ullamcorper morbi tincidunt ornare " +
                     "massa eget egestas. Ante metus dictum at tempor commodo ullamcorper " +
                     "a lacus vestibulum. Facilisis volutpat est velit egestas dui. Id " +
                     "cursus metus aliquam eleifend mi in. Nulla pharetra diam sit amet " +
                     "nisl suscipit adipiscing. Congue quisque egestas diam in arcu cursus " +
                     "euismod quis viverra. Sed vulputate odio ut enim blandit volutpat. " +
                     "Consectetur purus ut faucibus pulvinar elementum integer. In hac " +
                     "habitasse platea dictumst quisque sagittis. Odio tempor orci dapibus " +
                     "ultrices."
    },
    cameronFitzgerald: {
        name: "Cameron Fitzgerald",
        tagline: "Leader tagline",
        groupTypes: {
            music: true
        },
        img: "/img/mask/cam.jpg",
        link: "/leaders/cameronFitzgerald",
        offset: "50% 20%",
        bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do " +
                     "eiusmod tempor incididunt ut labore et dolore magna aliqua. Elit " +
                     "at imperdiet dui accumsan. Sed ullamcorper morbi tincidunt ornare " +
                     "massa eget egestas. Ante metus dictum at tempor commodo ullamcorper " +
                     "a lacus vestibulum. Facilisis volutpat est velit egestas dui. Id " +
                     "cursus metus aliquam eleifend mi in. Nulla pharetra diam sit amet " +
                     "nisl suscipit adipiscing. Congue quisque egestas diam in arcu cursus " +
                     "euismod quis viverra. Sed vulputate odio ut enim blandit volutpat. " +
                     "Consectetur purus ut faucibus pulvinar elementum integer. In hac " +
                     "habitasse platea dictumst quisque sagittis. Odio tempor orci dapibus " +
                     "ultrices."
    },
    dannyRippe: {
        name: "Danny Rippe",
        tagline: "Leader tagline",
        groupTypes: {
            music: true
        },
        img: "/img/mask/danny.jpg",
        link: "/leaders/dannyRippe",
        offset: "50% 20%",
        bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do " +
                     "eiusmod tempor incididunt ut labore et dolore magna aliqua. Elit " +
                     "at imperdiet dui accumsan. Sed ullamcorper morbi tincidunt ornare " +
                     "massa eget egestas. Ante metus dictum at tempor commodo ullamcorper " +
                     "a lacus vestibulum. Facilisis volutpat est velit egestas dui. Id " +
                     "cursus metus aliquam eleifend mi in. Nulla pharetra diam sit amet " +
                     "nisl suscipit adipiscing. Congue quisque egestas diam in arcu cursus " +
                     "euismod quis viverra. Sed vulputate odio ut enim blandit volutpat. " +
                     "Consectetur purus ut faucibus pulvinar elementum integer. In hac " +
                     "habitasse platea dictumst quisque sagittis. Odio tempor orci dapibus " +
                     "ultrices."
    },
    lucasMarcondes: {
        name: "Lucas Marcondes",
        tagline: "Leader tagline",
        groupTypes: {
            discussion: true
        },
        img: "/img/mask/lucas.jpg",
        link: "/leaders/lucasMarcondes",
        offset: "50% 20%",
        bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do " +
                     "eiusmod tempor incididunt ut labore et dolore magna aliqua. Elit " +
                     "at imperdiet dui accumsan. Sed ullamcorper morbi tincidunt ornare " +
                     "massa eget egestas. Ante metus dictum at tempor commodo ullamcorper " +
                     "a lacus vestibulum. Facilisis volutpat est velit egestas dui. Id " +
                     "cursus metus aliquam eleifend mi in. Nulla pharetra diam sit amet " +
                     "nisl suscipit adipiscing. Congue quisque egestas diam in arcu cursus " +
                     "euismod quis viverra. Sed vulputate odio ut enim blandit volutpat. " +
                     "Consectetur purus ut faucibus pulvinar elementum integer. In hac " +
                     "habitasse platea dictumst quisque sagittis. Odio tempor orci dapibus " +
                     "ultrices."
    },
    ryanRojas: {
        name: "Ryan Rojas",
        tagline: "Leader tagline",
        groupTypes: {
            music: true
        },
        img: "/img/mask/ryan.jpg",
        link: "/leaders/ryanRojas",
        offset: "50% 20%",
        bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do " +
                     "eiusmod tempor incididunt ut labore et dolore magna aliqua. Elit " +
                     "at imperdiet dui accumsan. Sed ullamcorper morbi tincidunt ornare " +
                     "massa eget egestas. Ante metus dictum at tempor commodo ullamcorper " +
                     "a lacus vestibulum. Facilisis volutpat est velit egestas dui. Id " +
                     "cursus metus aliquam eleifend mi in. Nulla pharetra diam sit amet " +
                     "nisl suscipit adipiscing. Congue quisque egestas diam in arcu cursus " +
                     "euismod quis viverra. Sed vulputate odio ut enim blandit volutpat. " +
                     "Consectetur purus ut faucibus pulvinar elementum integer. In hac " +
                     "habitasse platea dictumst quisque sagittis. Odio tempor orci dapibus " +
                     "ultrices."
    },
}