import React, { Component } from 'react'
import Modal from 'react-bootstrap/Modal';
import StartGroupForm from './../../start-group/components/StartGroupForm';
import './ApproveGroupCard.css';

class ApproveGroupCard extends Component {

    handleDone = () => {
        this.props.doneCallback();
    }

    render() {
        var group = this.props.group;
        console.log("Group Card Focus: " + group.focus);

        var newType = true;
        console.log("focus types: ");
        console.log(this.props.focusTypes);
        if (this.props.focusTypes !== null) {
            if (Object.keys(this.props.focusTypes).includes(group.focus)) {
                newType = false;
            }
        }

        console.log("new type: " + newType);

        return (
            <div>
                <Modal show={this.props.show} onHide={this.props.onHide} size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {this.props.editing ? 'Edit Group' : 'Approve Group'}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="approve-group-card-container">
                            <div className="approve-group-card-text">
                                <span className="approve-group-card-bold">Leader: </span>
                                <span>{group.leader[Object.keys(group.leader)[0]]}</span>
                            </div>
                            <div className="approve-group-card-text">
                                <span className="approve-group-card-bold">Focus: </span>
                                <span>{group.focus}</span>
                            </div>
                            <StartGroupForm 
                                group={group} 
                                approving={true} 
                                editing={this.props.editing}
                                focusTypes={this.props.focusTypes} 
                                newType={newType}
                                currUser={this.props.currUser}
                                doneCallback={this.handleDone}
                            />
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}

export default ApproveGroupCard