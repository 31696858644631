import React, {Component} from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './App.css';
import Homepage from "./components/pages/homepage/Homepage";
import Groups from "./components/pages/groups/Groups";
import Leaders from "./components/pages/leaders/Leaders";
import LogIn from "./components/pages/log-in/LogIn";
import CreateAccount from "./components/pages/create-account/CreateAccount";
import StartGroup from "./components/pages/start-group/StartGroup";
import ApproveGroups from "./components/pages/approve-groups/ApproveGroups";
import JoinGroup from "./components/pages/join-group/JoinGroup";
import AboutUs from "./components/pages/about-us/AboutUs";
import YourGroups from "./components/pages/your-groups/YourGroups";
import Profile from "./components/pages/profile/Profile";
// import ScrollToTop from "./components/scrolling/ScrollToTop";
import Error from "./components/pages/error/Error";
import { AuthProvider } from "./Auth";
import PrivateRoute from './PrivateRoute';
import Playground from './components/pages/playground/Playground';

// Global Constants
export const defaultProfileImg = "https://i.imgur.com/lPmczNu.jpg";
export const BkgrdColorPairs = [
    ['(5, 9, 245, 1)', '(236, 143, 197, 1)'],
    ['(85,255,225, 1)', '(36, 125, 51, 1)'],
    ['(38, 23, 82, 1)', '(224, 0, 183, 1)'],
    ['(166, 0, 22, 1)', '(224, 123, 0, 1)']
]

class App extends Component {

    render() {
        return (
            <AuthProvider value={this.state}>
                <BrowserRouter basename={process.env.PUBLIC_URL} onUpdate={() => window.scrollTo(0,0)}>
                    <div>
                        {/* <ScrollToTop/> */}
                        <Switch>
                            <Route path="/" component={Homepage} exact/>
                            <Route path="/groups/:groupType" component={Groups} exact/>
                            <Route path="/leaders/:leaderName" component={Leaders} exact/>
                            <Route path="/log-in" component={LogIn} exact/>
                            <Route path="/create-account" component={CreateAccount} exact/>
                            <PrivateRoute path="/start-group" component={StartGroup} exact/>
                            <PrivateRoute path="/approve-groups" component={ApproveGroups} exact/>
                            <PrivateRoute path="/profile" component={Profile} exact/>
                            <Route path="/join-group" component={JoinGroup} exact/>
                            <Route path="/about-us" component={AboutUs} exact/>
                            <Route path="/your-groups" component={YourGroups} exact/>
                            <Route path="/playground" component={Playground} exact/>
                            <Route render={(props) => <Error {...props}/>}/>
                        </Switch>
                    </div>
                </BrowserRouter>
            </AuthProvider>
        );
    }
}

export default App;
