import React, {Component} from 'react';
import NavBar from "./../../nav-bar/NavBar";
import Footer from "./../../footer/Footer";
import { LeaderData } from '../../../data/LeaderData';
import { GroupTypeData } from '../../../data/GroupTypeData';
import Hero from '../../hero/Hero';
import Coaster from '../../coaster/Coaster';
import './Leaders.css';
import ScrollToTop from './../../scrolling/ScrollToTop';

class Leaders extends Component {
    constructor(props) {
        super(props);

        this.leaderData = LeaderData[this.props.match.params.leaderName];
        this.groupTypes = Object.keys(this.leaderData.groupTypes);
        this.groupData = GroupTypeData[this.groupTypes[0]];
    }
    
    render() {
        return (
            <div>
                <ScrollToTop />
                <NavBar />
                <Hero 
                    img={this.groupData.img}
                    imgVerticalOffset={this.groupData.imgVerticalOffset}
                    imgOpacity={this.groupData.imgOpacity}
                    id="leaders-hero"
                />
                <div id="profile-coaster-container">
                    <Coaster
                        name={this.leaderData.name}
                        img={this.leaderData.img}
                        offset={this.leaderData.offset}
                        isLink={false}
                        id="coaster-moveable"
                    />
                </div>
                <div id="profile-container">
                    <div className="leader-container">
                        <div className="text-wrapper leader-bio-text">
                            <h3 className="category-header">{this.leaderData.name}</h3>
                            <p>{this.leaderData.bio}</p>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}

export default Leaders