/*
    key name: accessed by link paramter, so should be formatted according
              to last piece of link key
    title: Used for card on home page and hero title
    subtitle: Used on hero image on info page
    tagline: Used as text header on info page
    img: link to the card img and hero img
    imgVerticalOffset: used for hero img on info page. This is the percent shifted
                       vertically from the top. 50% centers the img vertically and
                       should be the default.
    imgOpacity: used for the hero img on the info page
    link: router link that is called when card is clicked.
    description: used for first block of text on the info page.
*/
export const GroupTypeData = {
    skating: {
        title: "Skating Groups",
        subtitle: "Where a shredded knee is just another day.",
        tagline: "Grind the rail.",
        img: "/img/boarding/sykes1.jpg",
        imgVerticalOffset: "38%",
        imgOpacity: "0.7",
        link: "/groups/skating",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do " +
                     "eiusmod tempor incididunt ut labore et dolore magna aliqua. Elit " +
                     "at imperdiet dui accumsan. Sed ullamcorper morbi tincidunt ornare " +
                     "massa eget egestas. Ante metus dictum at tempor commodo ullamcorper " +
                     "a lacus vestibulum. Facilisis volutpat est velit egestas dui. Id " +
                     "cursus metus aliquam eleifend mi in. Nulla pharetra diam sit amet " +
                     "nisl suscipit adipiscing. Congue quisque egestas diam in arcu cursus " +
                     "euismod quis viverra. Sed vulputate odio ut enim blandit volutpat. " +
                     "Consectetur purus ut faucibus pulvinar elementum integer. In hac " +
                     "habitasse platea dictumst quisque sagittis. Odio tempor orci dapibus " +
                     "ultrices."
    },
    music: {
        title: "Music Groups",
        subtitle: "Where the beat never stops.",
        tagline: "Feel the beat.",
        img: "/img/music/journey3.jpg",
        imgVerticalOffset: "25%",
        imgOpacity: "0.7",
        link: "/groups/music",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do " +
                     "eiusmod tempor incididunt ut labore et dolore magna aliqua. Elit " +
                     "at imperdiet dui accumsan. Sed ullamcorper morbi tincidunt ornare " +
                     "massa eget egestas. Ante metus dictum at tempor commodo ullamcorper " +
                     "a lacus vestibulum. Facilisis volutpat est velit egestas dui. Id " +
                     "cursus metus aliquam eleifend mi in. Nulla pharetra diam sit amet " +
                     "nisl suscipit adipiscing. Congue quisque egestas diam in arcu cursus " +
                     "euismod quis viverra. Sed vulputate odio ut enim blandit volutpat. " +
                     "Consectetur purus ut faucibus pulvinar elementum integer. In hac " +
                     "habitasse platea dictumst quisque sagittis. Odio tempor orci dapibus " +
                     "ultrices."
    },
    climbing: {
        title: "Climbing Groups",
        subtitle: "Where El Cap is just another route.",
        tagline: "Rock on.",
        img: "/img/climbing/climbing-group.jpg",
        imgVerticalOffset: "100%",
        imgOpacity: "0.7",
        link: "/groups/climbing",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do " +
                     "eiusmod tempor incididunt ut labore et dolore magna aliqua. Elit " +
                     "at imperdiet dui accumsan. Sed ullamcorper morbi tincidunt ornare " +
                     "massa eget egestas. Ante metus dictum at tempor commodo ullamcorper " +
                     "a lacus vestibulum. Facilisis volutpat est velit egestas dui. Id " +
                     "cursus metus aliquam eleifend mi in. Nulla pharetra diam sit amet " +
                     "nisl suscipit adipiscing. Congue quisque egestas diam in arcu cursus " +
                     "euismod quis viverra. Sed vulputate odio ut enim blandit volutpat. " +
                     "Consectetur purus ut faucibus pulvinar elementum integer. In hac " +
                     "habitasse platea dictumst quisque sagittis. Odio tempor orci dapibus " +
                     "ultrices."
    },
    discussion: {
        title: "Discussion Groups",
        subtitle: "Where Plato comes to challenge himself.",
        tagline: "Fascinating.",
        img: "/img/discussion/smretreat4.jpg",
        imgVerticalOffset: "70%",
        imgOpacity: "0.5",
        link: "/groups/discussion",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do " +
                     "eiusmod tempor incididunt ut labore et dolore magna aliqua. Elit " +
                     "at imperdiet dui accumsan. Sed ullamcorper morbi tincidunt ornare " +
                     "massa eget egestas. Ante metus dictum at tempor commodo ullamcorper " +
                     "a lacus vestibulum. Facilisis volutpat est velit egestas dui. Id " +
                     "cursus metus aliquam eleifend mi in. Nulla pharetra diam sit amet " +
                     "nisl suscipit adipiscing. Congue quisque egestas diam in arcu cursus " +
                     "euismod quis viverra. Sed vulputate odio ut enim blandit volutpat. " +
                     "Consectetur purus ut faucibus pulvinar elementum integer. In hac " +
                     "habitasse platea dictumst quisque sagittis. Odio tempor orci dapibus " +
                     "ultrices."
    },
    spiritual: {
        title: "Spiritual Groups",
        subtitle: "Where the Spirit moves in not-so-mysterious ways",
        tagline: "Amen.",
        img: "/img/music/circle-ryan.jpg",
        imgVerticalOffset: "50%",
        imgOpacity: "0.7",
        link: "/groups/spiritual",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do " +
                     "eiusmod tempor incididunt ut labore et dolore magna aliqua. Elit " +
                     "at imperdiet dui accumsan. Sed ullamcorper morbi tincidunt ornare " +
                     "massa eget egestas. Ante metus dictum at tempor commodo ullamcorper " +
                     "a lacus vestibulum. Facilisis volutpat est velit egestas dui. Id " +
                     "cursus metus aliquam eleifend mi in. Nulla pharetra diam sit amet " +
                     "nisl suscipit adipiscing. Congue quisque egestas diam in arcu cursus " +
                     "euismod quis viverra. Sed vulputate odio ut enim blandit volutpat. " +
                     "Consectetur purus ut faucibus pulvinar elementum integer. In hac " +
                     "habitasse platea dictumst quisque sagittis. Odio tempor orci dapibus " +
                     "ultrices."
    },
    hiking: {
        title: "Hiking Groups",
        subtitle: "Where the outdoors are just another bedroom.",
        tagline: "Keep walking.",
        img: "/img/hiking/trek-snow.jpg",
        imgVerticalOffset: "70%",
        imgOpacity: "0.7",
        link: "/groups/hiking",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do " +
                     "eiusmod tempor incididunt ut labore et dolore magna aliqua. Elit " +
                     "at imperdiet dui accumsan. Sed ullamcorper morbi tincidunt ornare " +
                     "massa eget egestas. Ante metus dictum at tempor commodo ullamcorper " +
                     "a lacus vestibulum. Facilisis volutpat est velit egestas dui. Id " +
                     "cursus metus aliquam eleifend mi in. Nulla pharetra diam sit amet " +
                     "nisl suscipit adipiscing. Congue quisque egestas diam in arcu cursus " +
                     "euismod quis viverra. Sed vulputate odio ut enim blandit volutpat. " +
                     "Consectetur purus ut faucibus pulvinar elementum integer. In hac " +
                     "habitasse platea dictumst quisque sagittis. Odio tempor orci dapibus " +
                     "ultrices."
    },
    biking: {
        title: "Biking Groups",
        subtitle: "Handlebars? Just an optional luxury.",
        tagline: "Just send it.",
        img: "/img/biking/biking-pair.jpg",
        imgVerticalOffset: "20%",
        imgOpacity: "0.7",
        link: "/groups/biking",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do " +
                     "eiusmod tempor incididunt ut labore et dolore magna aliqua. Elit " +
                     "at imperdiet dui accumsan. Sed ullamcorper morbi tincidunt ornare " +
                     "massa eget egestas. Ante metus dictum at tempor commodo ullamcorper " +
                     "a lacus vestibulum. Facilisis volutpat est velit egestas dui. Id " +
                     "cursus metus aliquam eleifend mi in. Nulla pharetra diam sit amet " +
                     "nisl suscipit adipiscing. Congue quisque egestas diam in arcu cursus " +
                     "euismod quis viverra. Sed vulputate odio ut enim blandit volutpat. " +
                     "Consectetur purus ut faucibus pulvinar elementum integer. In hac " +
                     "habitasse platea dictumst quisque sagittis. Odio tempor orci dapibus " +
                     "ultrices."
    },
    game: {
        title: "Game Groups",
        subtitle: "Where bonding rivalries are born.",
        tagline: "Game On.",
        img: "/img/discussion/cabin-game.jpg",
        imgVerticalOffset: "50%",
        imgOpacity: "0.5",
        link: "/groups/game",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do " +
                     "eiusmod tempor incididunt ut labore et dolore magna aliqua. Elit " +
                     "at imperdiet dui accumsan. Sed ullamcorper morbi tincidunt ornare " +
                     "massa eget egestas. Ante metus dictum at tempor commodo ullamcorper " +
                     "a lacus vestibulum. Facilisis volutpat est velit egestas dui. Id " +
                     "cursus metus aliquam eleifend mi in. Nulla pharetra diam sit amet " +
                     "nisl suscipit adipiscing. Congue quisque egestas diam in arcu cursus " +
                     "euismod quis viverra. Sed vulputate odio ut enim blandit volutpat. " +
                     "Consectetur purus ut faucibus pulvinar elementum integer. In hac " +
                     "habitasse platea dictumst quisque sagittis. Odio tempor orci dapibus " +
                     "ultrices."
    },
}