import React, { Component } from 'react';
import { rtDatabase, fireAuth } from './../../../Fire';
import NavBar from "./../../nav-bar/NavBar";
import Hero from "./../../hero/Hero";
import Loading from './../loading/Loading';
import GroupCard from './../../group-card/GroupCard';
import { Redirect } from 'react-router-dom';
import './JoinGroup.css';
import Alert from './../../alert/Alert';

class JoinGroup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            groups: {},
            currUser: '',
            error: '',
            showAlert: false
        }
    }

    componentDidMount() {
        // load group objects
        rtDatabase.ref('groups/').once('value').then(snapshot => {
            var groups = snapshot.val();
            console.log("Groups: ");
            console.log(groups);
            console.log("setting group state");
            this.setState({groups: groups})
        }).catch(error => {
            this.setState({error: error})
        })

        // load current user
        if (fireAuth.currentUser != null) {
            var uid = fireAuth.currentUser.uid;
            rtDatabase.ref('users/' + uid).once('value').then(snapshot => {
                var user = snapshot.val();
                console.log('setting curr user state');
                console.log(user);
                this.setState({
                    currUser: user
                });
            }).catch(error => {
                this.setState({error: error})
            })
        }  
    }

    makeGroupArray() {
        var groupArray = [];
        var defaultView = (
            <h2>There are no available groups, please check back later! 😅</h2>
        );
        if (this.state.groups !== null) {
            groupArray = Object.keys(this.state.groups).filter(key => {
                var hasMember = Object.values(this.state.groups[key].members).includes(this.state.currUser.firstname + ' ' + this.state.currUser.lastname);
                return !this.state.groups[key].private && this.state.groups[key].approved && !hasMember;
            }).map(key => {
                return (
                    <div key={key} className="">
                        <GroupCard 
                            class="group-card" 
                            group={this.state.groups[key]} 
                            currUser={this.state.currUser}
                            join={true}
                            callback={this.handleJoinSuccess}
                        />
                    </div>
                )
            })
            return groupArray.length === 0 ? defaultView : groupArray
        } else {
            return defaultView;
        }
    }

    handleJoinSuccess = () => {
        console.log("hit callback");
        this.setState({showAlert: true});
    }

    closeAlert = () => {
        this.setState({showAlert: false});
        window.location.reload(true);
    }

    render() {
        if (this.state.error !== '') {
            return <Redirect to={{
                pathname: '/error',
                state: { code: this.state.error.code, message: this.state.error.message }
            }} />;
        } else {
            if (fireAuth.currentUser === null) {
                console.log("sending to login");
                return <Redirect to={{
                    pathname: '/log-in',
                    state: { redirect: '/join-group'}
                }} />;
            } else if (this.state.groups === '' || this.state.currUser === '') {
                return <Loading />;
            } else {
                console.log("group object");
                console.log(this.state.groups);
                return (
                    <div>
                        <NavBar />
                        <Alert
                            actionNeeded={false}
                            show={this.state.showAlert}
                            title="Success"
                            message="You have successfully joined a group!"
                            confirmCallback={this.closeAlert}
                        />
                        <Hero 
                            img="./img/music/journey3.jpg"
                            imgVerticalOffset="20%"
                            imgOpacity="0.7"
                            class="join-group-hero"
                            title="Join a Group"
                            subtitle="Your Journey Awaits"
                        />
                        <div className="group-cards-container">
                            {this.makeGroupArray()}
                        </div>
                        
                    </div>
                )
            }
        }
    }
}

export default JoinGroup
