import firebase from 'firebase';

// will use firebase emulator if set to true
var useEmulator = true;

// Set the configuration for your app
// TODO: Replace with your project's config object
const firebaseConfig = {
    apiKey: "AIzaSyCq4oNOeQqNUE6GrJhQxGdTmt4gjiigi20",
    authDomain: "small-groups-wwu.firebaseapp.com",
    databaseURL: "https://small-groups-wwu-default-rtdb.firebaseio.com",
    projectId: "small-groups-wwu",
    storageBucket: "gs://small-groups-wwu.appspot.com",
    messagingSenderId: "374277656322",
    appId: "1:374277656322:web:257c16d19aa02787707c1b",
    measurementId: "G-KCHZEDS93R"
};
firebase.initializeApp(firebaseConfig);

// Get a reference to the database service
export var rtDatabase = firebase.database();
export var fireAuth = firebase.auth();
export var fireStorage = firebase.storage();
export var fireFuncs = firebase.functions();
if (window.location.hostname === "localhost" && useEmulator) {
    rtDatabase.useEmulator("localhost", 8001);
    fireAuth.useEmulator("http://localhost:9099");
    fireStorage.useEmulator("localhost", 9199);
    fireFuncs.useEmulator("localhost", 5001);
} else {
    console.log("not using emulator.");
}
