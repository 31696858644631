import React from 'react';
import { Spinner } from 'react-bootstrap';

const BtnSpinner = props => {
    return (
        <>
            <Spinner
                as="span"
                animation="grow"
                size='sm'
                role='status'
                aria-hidden='true'
                variant="warning"
                className={props.active ? '' : 'd-none'}
            />
            <span>  </span>
            <Spinner
                as="span"
                animation="grow"
                size='sm'
                role='status'
                aria-hidden='true'
                variant="primary"
                className={props.active ? '' : 'd-none'}
            />
            <span>  </span>
            <Spinner
                as="span"
                animation="grow"
                size='sm'
                role='status'
                aria-hidden='true'
                variant="success"
                className={props.active ? '' : 'd-none'}
            />
            {props.active ? "" : props.message}
        </>
    )
}

export default BtnSpinner;