import React from 'react';
import Hero from './../../hero/Hero';
import NavBar from './../../nav-bar/NavBar';
import Footer from './../../footer/Footer';
import Testemonials from './Testemonials';
import './AboutUs.css';

function AboutUs(props) {
    return (
        <div>
            <NavBar />
            <Hero
                img="./img/boarding/sykes2.jpg"
                imgVerticalOffset="50%"
                imgOpacity="0.7"
                class="about-us-hero"
                title="About Small Groups"
                subtitle="What's the big deal?"
            />
            <div className="about-us-body">
                <h1 className="about-us-title">Testemonials</h1>
                <p className="about-us-text">
                    What do past group leaders and members have to say about
                    Small Groups?
                </p>
                <div className="all-testemonials-container">
                    {Testemonials.map(item => {
                        return (
                            <div className="testemonials-container">
                                <div className="testemonial-img-wrapper">
                                    <img
                                        alt="Testemonial Giver"
                                        className="testemonial-img"
                                        src={item.img}
                                        style={{objectPosition: "50%" + item.verticalOffset}}
                                    />
                                </div>
                                <div className="testemonial-text-wrapper">
                                    <h2 className="testemonial-title">{item.title}</h2>
                                    {item.text.map(paragraph => {
                                        return (
                                            <p className="testemonial-text">
                                                {paragraph}
                                            </p>
                                        )
                                    })}
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default AboutUs
