import React, { Component } from 'react';
import Coaster from '../../../coaster/Coaster';
import './LeaderGrid.css';

/*
    LeaderGrid
        A grid of coaster images with names underneath
    Props
    leaders: object of leader data
*/
class LeaderGrid extends Component {
    constructor(props) {
        super(props);

        // create array of keys for prop leaders
        this.leadersKeys = Object.keys(this.props.leaders);
    }
    render() {
        let coasterComponents = this.leadersKeys.map((item, index) => {
            return (
                <div key={index} className="col col-sm align-self-center" align="center" id="leader-col">
                    <Coaster 
                        img={this.props.leaders[item].img} 
                        name={this.props.leaders[item].name} 
                        link={this.props.leaders[item].link}
                        offset={this.props.leaders[item].offset}
                        isLink={true}
                        id="coaster-in-leaderGrid"
                    />
                    <h1 className="coaster-caption">{this.props.leaders[item].name}</h1>
                </div>
            )
        })
        return (
            <div className="container-fluid" id="leader-grid">
                <div className="row no-gutters">
                    {coasterComponents}
                </div>
            </div>
        )
    }
}

export default LeaderGrid