import React, { Component } from 'react';
import './NavBar.css';
import { fireAuth, rtDatabase } from '../../Fire';
import { Redirect } from 'react-router-dom';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';


class NavBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userLoggedIn: false,
            userName: '',
            userPermissions: '',
            redirect: false, 
            error: ''
        }
    }

    componentDidMount() {
        if (fireAuth.currentUser != null) {
            var uName = fireAuth.currentUser.displayName;
            var uid = fireAuth.currentUser.uid;
            rtDatabase.ref('users/' + uid).once('value').then(snapshot => {
                var user = snapshot.val();
                this.setState({
                    userPermissions: user.permissions,
                    userName: uName,
                    userLoggedIn: true
                });
            }).catch(error => {
                this.setState({error: error})
            })
        }
    }

    handleSignOut = event => {
        console.log("sign out clicked");
        fireAuth.signOut().then(() => {
            console.log("signout successful");
            this.setState({userLoggedIn: false, userName: '', redirect: true});
        }).catch(error => {
            console.log("Signout failed");
            console.log("error code: " + error.code);
            console.log("error message: " + error.message);
        })
    }

    getLeaderDisplayClass = () => {
        if (this.state.userLoggedIn && (this.state.userPermissions === 'leader' || this.state.userPermissions === 'admin')) {
            return '';
        } else {
            return 'd-none';
        }
    }

    getAdminDisplayClass = () => {
        if (this.state.userLoggedIn && this.state.userPermissions === 'admin') {
            return '';
        } else {
            return 'd-none';
        }
    }

    getAuthDisplayClass = () => {
        return this.state.userLoggedIn ? '' : 'd-none';
    }

    render() {
        var signInLinkClass = '';
        var profileLinkClass = '';
        if (this.state.userLoggedIn) {
            signInLinkClass = 'd-none';
        } else {
            profileLinkClass = 'd-none';
        }

        if (this.state.error !== '') {
            return <Redirect to={{
                pathname: '/error',
                state: { code: this.state.error.code, message: this.state.error.message }
            }} />;
        }

        if (this.state.redirect && this.props.location !== '/') {
            return <Redirect to='/' />;
        }
        return (
            <div>
                <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
                    <Navbar.Brand href="/">
                        <img src="img/icons/wwuWolf.png" alt="" width="30" height="30" className="d-inline-block align-top" />
                        Small Groups
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="mr-auto">
                            <Nav.Link href="/join-group">Join a Group</Nav.Link>
                            <Nav.Link href="/about-us">About Us(!)</Nav.Link>
                            <Nav.Link href="/your-groups" className={this.getAuthDisplayClass()}>Your Groups(!!)</Nav.Link>
                            <NavDropdown className={this.getLeaderDisplayClass()} title="For Leaders" id="collapsible-nav-dropdown">
                                <NavDropdown.Item href="/start-group">Start a Group</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item href="#action/3.4">Help (!!)</NavDropdown.Item>
                            </NavDropdown>
                            <NavDropdown className={this.getAdminDisplayClass()} title="For Admins" id="collapsible-nav-dropdown">
                                <NavDropdown.Item href="/approve-groups">Approve Groups(!)</NavDropdown.Item>
                                <NavDropdown.Item href="/users">Users(!!)</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item href="#action/3.4">Help (!!)</NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                        <Nav>
                            <Nav.Link className={signInLinkClass} href="/log-in">Sign In</Nav.Link>
                            <NavDropdown className={profileLinkClass} menuAlign="right" title="Profile" id="collapsible-nav-dropdown">
                                <Nav.Link href="/profile">View Profile(!!)</Nav.Link>
                                <Nav.Link onClick={this.handleSignOut}>Sign Out</Nav.Link>
                            </NavDropdown>
                            <Navbar.Text className={profileLinkClass + " navbar-profile-name"}>{this.state.userName}</Navbar.Text>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </div>
        )
    }
}

export default NavBar
