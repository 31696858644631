import React, { useState } from 'react';
import { rtDatabase, fireStorage } from './../../Fire';
import firebase from 'firebase';
import { Button, Modal, Form } from 'react-bootstrap';
import './PicUploadModal.css';

const PicUploadModal = props => {
    const [file, setFile] = useState({});
    const [tempURL, setTempURL] = useState(null);

    const onHide = () => {
        setTempURL(null)
        setFile(null)
        props.onHide();
    }

    const handleUploadChange = event => {
        let file = event.target.files[0];
        setFile(file);
        setTempURL(URL.createObjectURL(file));
    }

    const handleConfirmUpload = event => {
        var uploadTask = fireStorage.ref().child('images/' + props.folder + '/' + props.name).put(file);

        // Register three observers:
        // 1. 'state_changed' observer, called any time the state changes
        // 2. Error observer, called on failure
        // 3. Completion observer, called on successful completion
        uploadTask.on('state_changed', 
            (snapshot) => {
                // Observe state change events such as progress, pause, and resume
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log('Upload is ' + progress + '% done');
                switch (snapshot.state) {
                    case firebase.storage.TaskState.PAUSED: // or 'paused'
                        console.log('Upload is paused');
                        break;
                    case firebase.storage.TaskState.RUNNING: // or 'running'
                        console.log('Upload is running');
                        break;
                    default:
                        break;
                }
            }, 
            (error) => {
                // Handle unsuccessful uploads
                // A full list of error codes is available at
                // https://firebase.google.com/docs/storage/web/handle-errors
                switch (error.code) {
                    case 'storage/unauthorized':
                        // User doesn't have permission to access the object
                        console.log("user doesn't have permission")
                        break;
                    case 'storage/canceled':
                        // User canceled the upload
                        console.log("user cancelled upload")
                        break;
                    case 'storage/unknown':
                        // Unknown error occurred, inspect error.serverResponse
                        console.log("unknown error")
                        break;
                    default:
                        break;
                }
            }, 
            () => {
                // Handle successful uploads on complete
                // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                    console.log('File available at', downloadURL);
                    if (props.linkSaveLocation !== undefined) {
                        rtDatabase.ref(props.linkSaveLocation).set(downloadURL).then(() => {
                            // window.location.reload();
                            props.linkReturn(downloadURL);
                            props.onHide();
                        })
                    } else {
                        console.error("ERROR!! You did not pass a LinkSaveLocation prop to this component!!")
                    }
                });
            }
        );
    }

    console.log("empty prop", props.empty)
    return (
        <Modal centered show={props.show} onHide={onHide} >
            <Modal.Header closeButton>
                <Modal.Title>{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <div className={tempURL === null ? 'd-none' : 'pic-upload-preview-container'}>
                        <img className="pic-upload-modal-profile-preview" alt="preview" src={tempURL} />
                    </div>
                    <Form.Group>
                        <Form.File onChange={handleUploadChange} accept=".jpg, .png" id="exampleFormControlFile1" label="Upload .jpg or .png" />
                    </Form.Group>
                    <div className="upload-confirm-btn-wrapper">
                        <Button onClick={handleConfirmUpload} variant="primary">Upload</Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

export default PicUploadModal
