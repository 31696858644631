import React, { useContext, useState, useEffect } from 'react';
import * as EmailValidator from 'email-validator';
import { Link, Redirect, useLocation } from 'react-router-dom';
import './LogIn.css';
import { fireAuth } from '../../../Fire';
import { AuthContext } from './../../../Auth';

const LogIn = ({ history }) => {
    const [emailValue, setEmailValue] = useState('');
    const [emailValid, setEmailValid] = useState('');
    const [passwordValue, setPasswordValue] = useState('');
    const [passwordValid, setPasswordValid] = useState('');
    const [verified, setVerified] = useState(true);
    const [wrongPasswordDisplay, setWrongPasswordDisplay] = useState('d-none');
    const [wrongEmailDisplay, setWrongEmailDisplay] = useState('d-none');
    const location = useLocation();

    const isValidEmail = email => {
        return EmailValidator.validate(email);
    }

    useEffect(() => {
        setVerified(emailValid === '' && passwordValid === '');
    }, [emailValid, passwordValid]);

    const handleEmailChange = event => {
        let newValue = event.target.value;
        setEmailValue(newValue);
        if (isValidEmail(newValue)) {
            setEmailValid('');
        } else {
            setEmailValid('is-invalid');
        }
    }

    const handlePasswordChange = event => {
        let newValue = event.target.value;
        setPasswordValue(newValue);
        if (passwordValue.length > 32) {
            setPasswordValid('is-invalid');
        } else {
            setPasswordValid('');
        }
    }

    const handleLogin = event => {
            event.preventDefault();
            fireAuth.signInWithEmailAndPassword(emailValue, passwordValue)
            .then((user) => {
                // Signed in 
                //   this.setState({wrongEmailDisplay: 'd-none', wrongPasswordDisplay: 'd-none', loginRedirect: true})
                setWrongEmailDisplay('d-none');
                setWrongPasswordDisplay('d-none');
                console.log("in history redirect");
                if (location.state === undefined) {
                    history.push('/');
                } else {
                    history.push(location.state.redirect)
                }
            })
            .catch((error) => {
                if (error.code === "auth/user-not-found") {
                    //   this.setState({wrongEmailDisplay: '', wrongPasswordDisplay: 'd-none'});
                    setWrongEmailDisplay('');
                    setWrongPasswordDisplay('d-none');
                } else if (error.code === "auth/wrong-password") {
                    //   this.setState({wrongPasswordDisplay: '', wrongEmailDisplay: 'd-none'});
                    setWrongPasswordDisplay('');
                    setWrongEmailDisplay('d-none');
                }
            });
    };

    const { currentUser } = useContext(AuthContext);
    if (currentUser) {
        console.log("in current user redirect");
        if (location.state === undefined) {
            return <Redirect to='/' />;
        } else {
            return <Redirect to={location.state.redirect} />;
        }
    }
    
    return (
        <div className="sign-in-page" style={{backgroundImage: "/img/climbing/climbing-group.jpg"}}>
            <img className="sign-in-background-img" src="/img/climbing/climbing-group.jpg" alt="climbing"/>
            <div className="sign-in-card">
                <div className="sign-in-card-wrapper">
                    <h2 className="sign-in-header">Log In</h2>
                    <form onSubmit={handleLogin}>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="addon-wrapping">
                                    <img className="create-account-icons" src="/img/icons/envelope.svg" alt="envelop icon" title="envelope" />
                                </span>
                            </div>
                            <input onChange={handleEmailChange} type="email" id="emailInput" className={"form-control " + emailValid} placeholder="email" aria-label="email" aria-describedby="addon-wrapping" required autofocus/>
                            <div className="invalid-feedback">Please provide a valid email</div>
                        </div>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="addon-wrapping">
                                    <img className="create-account-icons" src="/img/icons/key.svg" alt="key icon" title="key" />
                                </span>
                            </div>
                            <input onChange={handlePasswordChange} type="password" id="passwordInput" className={"form-control " + passwordValid} placeholder="password" aria-label="password" aria-describedby="addon-wrapping" required/>
                            <div className="invalid-feedback">Password is too long</div>
                        </div>
                        <div className="sign-in-button-wrapper">
                            <button onClick={handleLogin}type="submit" className="btn btn-primary btn-lg" disabled={!verified}>Log In</button>
                        </div>
                        <div className="login-errors">
                            <p className={"login-error-message " + wrongEmailDisplay}>A user with that email doesn't exist</p>
                            <p className={"login-error-message " + wrongPasswordDisplay}>Incorrect password</p>
                        </div>
                    </form>
                    <div className="sign-in-links">
                        <Link to="/">Forgot password?</Link>
                        <br/>
                        <Link to="/create-account">Create Account</Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LogIn;