import React, { Component } from 'react';
import NavBar from './../../nav-bar/NavBar';
import Loading from './../loading/Loading';
import { rtDatabase, fireAuth } from './../../../Fire';
import { Redirect } from 'react-router';
import Alert from './../../alert/Alert';
import ApproveGroupCard from './components/ApproveGroupCard';
import './ApproveGroups.css';
import DataTable from '../../data-table/DataTable';

class ApproveGroups extends Component {
    constructor(props) {
        super(props)
        this.state ={
            groups: '',
            tableGroups: '',
            focusTypes: '',
            currUser: '',
            showGroupCard: false,
            editingGroup: false,
            groupClicked: '',           // key for group selected
            error: '',
            showApprovingAlert: false,
            showDeleteAlert: false
        }
    }

    handleApproveClick = key => {
        // console.log("handleClick group focus: " + this.state.groups[key].focus);
        // this.setState({groupClicked: this.state.groups[Object.keys(this.state.groups)[0]], showGroupCard: true})
        this.setState({groupClicked: key, showGroupCard: true, editingGroup: false})
    }

    handleEditClick = key => {
        this.setState({groupClicked: key, showGroupCard: true, editingGroup: true})
    }

    handleCloseCard = () => {
        this.setState({showGroupCard: false})
        window.location.reload();
    }

    formatGroups = groups => {
        // This deep copies the object so it won't be changed
        // (normally '=' operator creates a reference)
        var newGroups = JSON.parse(JSON.stringify(groups));
        Object.keys(groups).filter(key => key !== 'placeholder').forEach(key => {
            var group = groups[key];
            newGroups[key].dateAdded = group.dateAdded.month + "/" + group.dateAdded.day + "/" + group.dateAdded.year;
            newGroups[key].leader = group.leader[Object.keys(group.leader)[0]];
            newGroups[key].endDate = String(group.endDate).replace(/^(\w*\s\w*\s\d*)(\s\d*)(.*)$/,'$1,$2');
            newGroups[key].private = group.private ? 'Private' : 'Public';
        })
        return newGroups;
    }   

    handleApproveDone = () => {
        var stateObj = {
            showGroupCard: false,
            showApprovingAlert: true,
            alertTitle: "Success"
        }
        if (this.state.editingGroup) {
            stateObj.alertMessage = "The group was edited successfully."
        } else {
            stateObj.alertMessage = "The group was approved successfully."
        } 
        this.setState(stateObj);
    }

    alertCallback = () => {
        this.setState({showApprovingAlert: false, showDeleteAlert: false}, () => {
            window.location.reload(true);
        })
    }

    showDeleteAlert = key => {
        this.setState({showDeleteAlert: true, groupClicked: key})
    }

    cancelCallback = () => {
        this.setState({showApprovingAlert: false, showDeleteAlert: false})
    }

    deleteGroup = () => {
        var updates = {};
        // removes group
        updates['/groups/' + this.state.groupClicked] = null;
        // removes group from all member users' db entries
        Object.keys(this.state.groups[this.state.groupClicked].members).forEach(uid => {
            updates['/users/' + uid + '/groups/' + this.state.groupClicked] = null
        });
        rtDatabase.ref().update(updates).then(() => {
            this.setState({showDeleteAlert: false}, () => {
                window.location.reload(true);
            })
        }).catch(error => {
            this.setState({error: error})
        })
    }

    componentDidMount() {
        rtDatabase.ref('groups/').once('value').then(snapshot => {
            var groups = snapshot.val();
            var tableGroups = this.formatGroups(groups);
            this.setState({groups: groups, groupClicked: Object.keys(groups)[0], tableGroups: tableGroups}, () => {
                rtDatabase.ref('groupFocusTypes/').once('value').then(snapshot2 => {
                    this.setState({focusTypes: snapshot2.val(), loaded: true})
                }).catch(error => {
                    this.setState({error: error})
                })
            });
        }).catch(error => {
            this.setState({error: error})
        })
        if (fireAuth.currentUser != null) {
            var uid = fireAuth.currentUser.uid;
            rtDatabase.ref('users/' + uid).once('value').then(snapshot => {
                var user = snapshot.val();
                this.setState({
                    currUser: user
                });
            }).catch(error => {
                this.setState({error: error})
            })
        }
    }

    render() {
        var approveGroupCard = '';
        if (this.state.groups === 'empty') {
            approveGroupCard = <div></div>
        } else {
            approveGroupCard = <ApproveGroupCard 
                show={this.state.showGroupCard} 
                onHide={this.handleCloseCard} 
                group={this.state.groups[this.state.groupClicked]}
                focusTypes={this.state.focusTypes}
                currUser={this.state.currUser}
                doneCallback={this.handleApproveDone}
                editing={this.state.editingGroup}
            />
        }

        if (this.state.error !== '') {
            return <Redirect to={{
                pathname: '/error',
                state: { code: this.state.error.code, message: this.state.error.message }
            }} />;
        } else if (this.state.groups === '' || 
                   this.state.focusTypes === '' || 
                   this.state.currUser === '' || 
                   this.state.tableGroups === '') {
            return (
                <Loading/>
            )
        } else {
            console.log("Groups: ");
            console.log(this.state.groups);
            console.log("Group: ");
            console.log(this.state.groupClicked);
            return (
                <div>
                    <NavBar />
                    <h1 className="approve-groups-header">Pending Groups</h1>
                    <div className="approve-groups-table-container">
                        <DataTable
                            headers={["Date Added", "Leader Name", "Focus", "End Date", "Visibility"]}
                            data={this.state.tableGroups}
                            dataFields={["dateAdded", "leader", "focus", "endDate", "private"]}
                            criteria={data => {return !data.approved}}
                            buttons={[
                                {
                                    variant: "primary",
                                    handleClick: this.handleApproveClick,
                                    title: "Approve Group",
                                    icon: "bi-check"
                                }
                            ]}
                        />
                        {approveGroupCard}
                    </div>
                    <h1 className="approve-groups-header">All Groups</h1>
                    <div className="approve-groups-table-container">
                        <DataTable
                            headers={["Date Added", "Leader Name", "Focus", "End Date", "Visibility"]}
                            data={this.state.tableGroups}
                            dataFields={["dateAdded", "leader", "focus", "endDate", "private"]}
                            criteria={data => {return data.approved}}
                            buttons={[
                                {
                                    variant: "primary",
                                    handleClick: this.handleEditClick,
                                    title: "Edit Group",
                                    icon: "bi-pencil"
                                },
                                {
                                    variant: "secondary",
                                    handleClick: key => {console.log("would view: " + key)},
                                    title: "View Group",
                                    icon: "bi-eye"
                                },
                                {
                                    variant: "danger",
                                    handleClick: this.showDeleteAlert,
                                    title: "Delete Group",
                                    icon: "bi-trash"
                                }
                            ]}
                        />
                    </div>
                    <Alert
                        actionNeeded={false}
                        show={this.state.showApprovingAlert}
                        title={this.state.alertTitle}
                        message={this.state.alertMessage}
                        confirmCallback={this.alertCallback}
                    />
                    <Alert
                        confirmationNeeded={true}
                        show={this.state.showDeleteAlert}
                        title="Delete Group"
                        message="Are you sure you want to delete this group?"
                        confirmText={"Delete Group"}
                        cancelCallback={this.cancelCallback}
                        confirmCallback={this.deleteGroup}
                        variant="danger"
                        confirmCode="DELETE"
                    />

                </div>
            )
        }
    }
}

export default ApproveGroups