import React, { Component } from 'react';
import { GroupTypeData } from './../../../data/GroupTypeData';
import NavBar from "./../../nav-bar/NavBar";
import Footer from "./../../footer/Footer";
import Hero from '../../hero/Hero';
import ScrollToTop from './../../scrolling/ScrollToTop';

class Groups extends Component {
    constructor(props) {
        super(props);
        this.groupData = GroupTypeData[this.props.match.params.groupType];
    }
    render() {
        return (
            <div>
                <ScrollToTop />
                <NavBar />
                <Hero 
                    img={this.groupData.img}
                    imgVerticalOffset={this.groupData.imgVerticalOffset}
                    imgOpacity={this.groupData.imgOpacity}
                    title={this.groupData.title}
                    subtitle={this.groupData.subtitle}
                />
                <div className="text-wrapper">
                    <h3 className="category-header">{this.groupData.tagline}</h3>
                    <p>{this.groupData.description}</p>
                </div>
                <Footer />
            </div>
        )
    }
}

export default Groups