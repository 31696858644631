import React, { Component } from 'react';
import NavBar from "./../../nav-bar/NavBar";
import Hero from "./../../hero/Hero";
import StartGroupForm from './components/StartGroupForm';
import { rtDatabase, fireAuth } from './../../../Fire';
import Loading from './../loading/Loading';
import { Redirect } from 'react-router';
import Alert from './../../alert/Alert';
import "react-datepicker/dist/react-datepicker.css";
import './StartGroup.css';

class StartGroup extends Component {
    constructor(props) {
        super(props)
        this.state = {
            groupFocusTypes: '',
            currUser: '',
            error: '',
            doneRedirect: false,
            showAlert: false
        }
    }

    componentDidMount() {
        // load group focus types
        rtDatabase.ref('groupFocusTypes').once('value').then(snapshot => {
            var types = snapshot.val();
            this.setState({groupFocusTypes: types});
        }).catch(error => {
            this.setState({
                error: error
            })
        })
        if (fireAuth.currentUser != null) {
            var uid = fireAuth.currentUser.uid;
            rtDatabase.ref('users/' + uid).once('value').then(snapshot => {
                var user = snapshot.val();
                this.setState({
                    currUser: user
                });
            }).catch(error => {
                this.setState({
                    error: {
                        code: error.code,
                        message: error.message
                    }
                })
            })
        }     
    }

    formDoneCallback = () => {
        this.setState({showAlert: true})
    }

    alertCallback = () => {
        this.setState({showModal: false, doneRedirect: true})
    }

    render() {
        if (this.state.error !== '') {
            return <Redirect to={{
                pathname: '/error',
                state: { code: this.state.error.code, message: this.state.error.message }
            }} />;
        }
        if (this.state.doneRedirect) {
            return <Redirect to='/' />;
        }
        if (this.state.groupFocusTypes === '' || this.state.currUser === '') {
            return (
                <Loading />
            )
        } else {
            return (
                <div>
                    <NavBar />
                    <Hero 
                        img="./img/rafting/rafting-front.jpg"
                        imgVerticalOffset="50%"
                        imgOpacity="0.7"
                        class="start-group-hero"
                    />
                    <div className="start-group-page-container">
                        <div className="start-group-form-container">
                            <h1 className="start-group-title">Start a Small Group</h1>
                            <p className="start-group-instructions">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                                quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                                cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                                proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                            </p>
                            <StartGroupForm 
                                approving={false} 
                                focusTypes={this.state.groupFocusTypes} 
                                currUser={this.state.currUser}
                                doneCallback={this.formDoneCallback}
                            />
                        </div>
                    </div>
                    <Alert
                        actionNeeded={false}
                        show={this.state.showAlert}
                        title="Success"
                        message="The group was created successfully."
                        confirmCallback={this.alertCallback}
                    />
                </div>
            )
        }
    }
}

export default StartGroup