export const CarouselData = {
    a: {
        source: "img/biking/biking-back.jpg",
        captionHead: "Ride Together",
        captionP: "Tackle the jumps and hurdles of life with ease.",
        opacity: 0.6
    },
    b: {
        source: "img/climbing/climbing-alexa-cut.jpg",
        captionHead: "Reach Higher",
        captionP: "Reach new heights with great people cheering you on.",
        opacity: 0.45
    },
    c: {
        source: "img/music/openmic-cut.jpg",
        captionHead: "Sing Together",
        captionP: "Let loose and sing like no one's watching.",
        opacity: 0.45
    },
    d: {
        source: "img/biking/biking-air.jpg",
        captionHead: "Get Air",
        captionP: "Soar to new heights with a group that supports you.",
        opacity: 0.7
    },
    e: {
        source: "img/discussion/smretreat5-cut.jpg",
        captionHead: "Be Inspired",
        captionP: "Work through life and dive into deep topics with caring friends.",
        opacity: 0.5
    }
}